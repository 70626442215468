import { Theme } from "@mui/material";
import { makeStyles } from "tss-react/mui";

import { SPACING } from "../../../theme/spacing";

export type ClassNames = "root";

export const useStyles = makeStyles<void, ClassNames>()((theme: Theme) => ({
  root: {
    padding: SPACING.s_48,
    // justifyContent: "space-between",
    "& button": {
      marginLeft: SPACING.s_24,
    },
    "& >:not(style)~:not(style)": {
      marginLeft: SPACING.s_24,
    },
  },
}));
