import { Radio as MUIRadio } from "@mui/material";
import React, { FC } from "react";

import Props from "./props";
import { useStyles } from "./styles";

const Radio: FC<Props> = ({ ...rest }) => {
  const { classes } = useStyles();
  return (
    <MUIRadio
      disableRipple
      icon={<></>}
      checkedIcon={<div className={classes.checked}></div>}
      classes={{ root: classes.root }}
      {...rest}
    />
  );
};

export default Radio;
