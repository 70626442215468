import { Theme } from "@mui/material";
import { makeStyles } from "tss-react/mui";

import { COLORS } from "../../../theme/colors";

export type ClassNames =
  | "root"
  | "navLink"
  | "navButton"
  | "nav"
  | "navLinkMenu"
  | "menu"
  | "menuButton";

export const useStyles = makeStyles<void, ClassNames>()((theme: Theme) => ({
  root: {
    minWidth: theme.breakpoints.values.xl,
  },
  nav: {
    display: "flex",
    alignItems: "center",
    "& >*": {
      marginRight: `30px !important`,
    },
    "& >:last-child": {
      marginRight: `0 !important`,
    },
  },

  navLink: {
    ...(theme.typography.body1 as any),
    color: COLORS.primary.grey40,
    textTransform: "capitalize",
    "&:hover": {
      color: COLORS.primary.white,
    },
    textDecoration: "none",
  },
  navButton: {
    ...(theme.typography.body1 as any),
    color: COLORS.primary.grey40,
    textTransform: "capitalize",
    padding: "12px 24px",
    background: "unset",
    borderRadius: "4px",
    border: `1px solid ${COLORS.primary.grey40}`,

    "&:hover": {
      color: COLORS.primary.white,
      border: `1px solid ${COLORS.primary.white}`,
      background: "unset",
    },
  },
  menu: {
    cursor: "initial",
    "&:hover": {
      background: "unset",
      cursor: "initial",
    },
  },
  navLinkMenu: {
    color: COLORS.primary.darkGreen,

    "&:hover": {
      color: COLORS.primary.green,
      background: "unset",
    },
  },
  menuButton: {
    "& svg": {
      color: COLORS.primary.grey40,
    },
    "&:hover": {
      "& > *": {
        color: COLORS.primary.white,
      },
      "& svg": {
        color: COLORS.primary.white,
      },
    },
  },
}));
