import { Theme } from "@mui/material";
import { makeStyles } from "tss-react/mui";

export type ClassNames = "root" | "ellipsis";

export const useStyles = makeStyles<void, ClassNames>()((theme: Theme) => ({
  root: {
    whiteSpace: "pre-line",
  },
  ellipsis: {
    overflow: "hidden",
    textOverflow: "ellipsis",
    display: "-webkit-box",
    WebkitLineClamp: "3",
    WebkitBoxOrient: "vertical",
  },
}));
