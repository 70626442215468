export enum UserNotificationsTypeEnum {
  NEW_OFFICE = "New office was added",
  RESEARCH_UPDATES = "Updates on My Requests",
  NEW_SAVED_SEARCH_OFFICE = "Saved Search Notifications",
}

export type UserNotificationsTypeKey = keyof typeof UserNotificationsTypeEnum;

export type User = {
  id: number;
  avatarFileName: string;
  createdAt: string;
  updatedAt: string;
  deletedAt: string | null;
  hashIntercom?: string;
  socialId?: string | null;
  firstName: string;
  title: string;
  lastName: string;
  phoneNumber: string;
  role?: RoleEnum | null;
  notificationSettings?: UserNotificationsTypeKey[];
  email: string;
};

export enum RoleEnum {
  superAdmin = "superAdmin",
  customer = "customer",
  admin = "admin",
}
