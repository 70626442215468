import { yupResolver } from "@hookform/resolvers/yup";
import { Box, FormGroup } from "@mui/material";
import { ThunkDispatch } from "@reduxjs/toolkit";
import * as React from "react";
import { FC, useRef, useState } from "react";
import { Controller, useController } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { Link as RouterLink, useNavigate } from "react-router-dom";

import { useApiRequest } from "../../../hooks/useApiRequest";
import {
  AuthAction,
  LOGIN_SUCCESS,
} from "../../../middleware/api/auth/stateEntity/actions";
import { StateAuth } from "../../../middleware/api/auth/stateEntity/reducer";
import { API_ENDPOINTS } from "../../../middleware/api/endpoints.api";
import { AppState } from "../../../store/rootReducer";
import { RootState } from "../../../store/store";
import { SPACING } from "../../../theme/spacing";
import Checkbox from "../../atoms/@form/Checkbox";
import FormControlLabel from "../../atoms/@form/FormControlLabel";
import TextField from "../../atoms/@form/TextField";
import useForm from "../../atoms/@form/useForm";
import CaptchaConsent from "../../atoms/CaptchaConsent";
import Link from "../../atoms/Link";
import Typography from "../../atoms/Typography";
import TextFieldPassword from "../../molecules/TextFieldPassword";
import TextFieldPhone from "../../molecules/TextFieldPhone";
import DialogPhoneNumberVerification from "../../organisms/@dialog/DialogPhoneNumberVerification";
import Authorization from "../../templates/Authorization";
import { DialogRef } from "../PageSettings/components/MyProfile";
import { PathPage } from "../pathPage";
import { validationSchema } from "./form";
import Props from "./props";
import { useStyles } from "./styles";

const PageSignUp: FC<Props> = () => {
  const { classes } = useStyles();

  const [requestState, sendRequest] = useApiRequest<any, any>(
    API_ENDPOINTS.AUTH.SIGN_UP,
    "POST",
  );

  const { isLoading } = useSelector<AppState, StateAuth>((state) => state.auth);

  const [isPhoneVerified, setIsPhoneVerified] = useState(false);

  const form = useForm({
    defaultValues: {
      firstName: "",
      lastName: "",
      email: "",
      phoneNumber: "",
      title: "",
      password: "",
      passwordConfirm: "",
      recaptcha: "",
      notificationSettings: [],
    },
    resolver: yupResolver(validationSchema),
  });

  const { setError, getValues, control } = form;

  const dispatch = useDispatch<ThunkDispatch<RootState, unknown, AuthAction>>();

  const navigate = useNavigate();

  const onSubmit = (data: any) => {
    if (isPhoneVerified) {
      sendSignupRequest(data);
    } else {
      dialogRefPhoneVerification.current?.handleOpen(data);
    }
  };

  const sendSignupRequest = (data: any) => {
    sendRequest({ ...data })
      .then((response) => {
        if (response) {
          const { token, refreshToken, user } = response;
          localStorage.setItem("token", token);
          localStorage.setItem("refreshToken", refreshToken);
          dispatch({ type: LOGIN_SUCCESS, payload: user });
          navigate(PathPage.SignUpSurvey, { state: data.email });
        }
      })
      .catch((error) => {
        if (
          error.response.data?.error?.message.includes(
            "signupEmailAlreadyExists",
          )
        ) {
          setError("email", {
            type: "custom",
            //@ts-ignore
            message: (
              <Typography variant="caption" color="error">
                An account with this email already exists. Please{" "}
                <Link
                  color="error"
                  variant="caption"
                  style={{ textDecoration: "underline" }}
                  component={RouterLink}
                  to={PathPage.SignIn}>
                  Sign in
                </Link>{" "}
                to your existing account.
              </Typography>
            ),
          });
        }

        if (
          error.response.data?.error?.message.includes(
            "signupPhoneNumberAlreadyExists",
          )
        ) {
          setError("phoneNumber", {
            type: "custom",
            //@ts-ignore
            message: (
              <Typography variant="caption" color="error">
                An account with this phone number already exists. Please{" "}
                <Link
                  color="error"
                  variant="caption"
                  style={{ textDecoration: "underline" }}
                  component={RouterLink}
                  to={PathPage.SignIn}>
                  Sign in
                </Link>{" "}
                to your existing account.
              </Typography>
            ),
          });
        }

        console.error("Error fetching requests:", error);
      });
  };

  const onDialogClose = (data: any) => {
    setIsPhoneVerified(true);
    sendSignupRequest(data);
  };

  const dialogRefPhoneVerification = useRef<DialogRef>(null);

  return (
    <Authorization className={classes.root}>
      <Box component="form" onSubmit={form.handleSubmit(onSubmit)}>
        <SectionCreate />
        <SectionAbout form={form} />

        <Box style={{ marginTop: SPACING.s_60 }}>
          <Controller
            name="notificationSettings"
            control={control}
            render={({ field }: any) => (
              <FormGroup>
                {["NEW_SAVED_SEARCH_OFFICE"].map((key) => (
                  <FormControlLabel
                    key={key}
                    control={
                      <Checkbox
                        checked={field.value.includes(key)}
                        onChange={(e) => {
                          const newValue = e.target.checked
                            ? [...field.value, key]
                            : field.value.filter((type: any) => type !== key);
                          field.onChange(newValue);
                        }}
                      />
                    }
                    label={"Send me updates and insights"}
                  />
                ))}
              </FormGroup>
            )}
          />
        </Box>

        <CaptchaConsent
          style={{ marginTop: SPACING.s_32 }}
          form={form}
          isLoading={isLoading || requestState.isLoading}
        />
      </Box>
      <DialogPhoneNumberVerification
        ref={dialogRefPhoneVerification}
        onVerify={onDialogClose}
      />
    </Authorization>
  );
};

const SectionCreate = () => {
  const { classes } = useStyles();

  return (
    <Box>
      <Typography variant="h1">Welcome to Family Office List!</Typography>
      <Typography marginTop="10px">
        We’re thrilled to have you here. By creating an account, you’ll unlock
        access to explore our platform, discover our subscription options, and
        enjoy a free trial with sample data. Get ready to connect with family
        offices and see the value we can offer firsthand!
      </Typography>
      <Box display="flex" marginTop="24px" flexDirection="row">
        <Typography variant="body1">Already have an account? </Typography>
        <Typography variant="body1">&nbsp;</Typography>
        <Link variant="h4" component={RouterLink} to={PathPage.SignIn}>
          Sign in {">"}
        </Link>
      </Box>
    </Box>
  );
};

const SectionAbout = ({
  form: {
    setValue,
    formState: { errors },
    register,
    control,
  },
}: any) => {
  const { classes } = useStyles();

  const { field } = useController({
    name: "phoneNumber",
    control,
  });

  return (
    <Box className={classes.section}>
      <Typography variant="h2" className={classes.sectionTitle}>
        Tell us a bit about yourself
      </Typography>
      <Box className={classes.sectionAboutContent}>
        <Box display="flex" justifyContent="space-between">
          <TextField
            style={{ width: "50%", marginRight: "40px" }}
            id="firstName"
            label="First Name"
            {...register("firstName")}
            error={!!errors.firstName}
            helperText={errors.firstName?.message}
          />
          <TextField
            style={{ width: "50%" }}
            id="lastName"
            label="Last Name"
            {...register("lastName")}
            error={!!errors.lastName}
            helperText={errors.lastName?.message}
          />
        </Box>

        <TextField
          id="title"
          label="Title"
          {...register("title")}
          error={!!errors.title}
          helperText={errors.title?.message}
        />

        <TextField
          id="email"
          label="Business Email"
          type="email"
          {...register("email")}
          error={!!errors.email}
          helperText={errors.email?.message}
        />

        <TextFieldPhone
          {...field}
          error={!!errors.phoneNumber}
          helperText={errors.phoneNumber?.message}
          control={control}
        />

        <TextFieldPassword
          id="password"
          label={"Create Password"}
          {...register("password")}
          error={!!errors.password}
          helperText={errors.password?.message}
        />
        <TextFieldPassword
          id="passwordConfirm"
          label={"Confirm Password"}
          {...register("passwordConfirm")}
          error={!!errors.passwordConfirm}
          helperText={errors.passwordConfirm?.message}
        />
      </Box>
    </Box>
  );
};

export default PageSignUp;
