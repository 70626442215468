import { Button as MUIButton } from "@mui/material";
import clsx from "clsx";
import React, { FC } from "react";

import CircularProgress from "../CircularProgress";
import Props from "./props";
import { useStyles } from "./styles";

const Button: FC<Props> = ({
  children,
  typeClassName = "primary",
  isSpinner = false,
  className,
  disabled,
  ...rest
}) => {
  const { classes } = useStyles();

  return (
    <MUIButton
      className={clsx(classes[typeClassName], className)}
      type="button"
      disableRipple
      {...rest}
      disabled={isSpinner || disabled}>
      {children}
      {isSpinner && <CircularProgress isCenterFlex />}
    </MUIButton>
  );
};

export default Button;
