import axios from "axios";
import i18next from "i18next";

import { API_VERSION, BASE_URL } from "./api/endpoints.api";
import {
  blacklistedFlow,
  get422ErrorMessage,
  isBlacklisted,
  isError5xx,
  isError400,
  isError401,
  isError408,
  isError422,
  isError429,
  isErrorForbidden,
  isLoginEndpoint,
  unauthorizedFlow,
} from "./utils";

const SECONDS = 30;

export const http = axios.create({
  baseURL: BASE_URL + API_VERSION,
  timeout: SECONDS * 1000,
  withCredentials: true,
  headers: {
    "Content-Type": "application/json",
  },
});

http.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem("token");
    if (token) {
      config.headers["Authorization"] = `Bearer ${token}`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  },
);

export const handleError = (showErrorAlert: (message: string) => void) => {
  http.interceptors.response.use(
    (response) => response,
    (error) => {
      const originalRequest = error.config;
      if (
        isBlacklisted(
          error.response.status,
          error?.response?.data?.error?.message,
        )
      ) {
        return blacklistedFlow(showErrorAlert);
      } else if (
        isError401(error.response.status) &&
        !isLoginEndpoint(originalRequest.url) &&
        !originalRequest._retry
      ) {
        return unauthorizedFlow(originalRequest, showErrorAlert);
      } else if (isErrorForbidden(error.response.status)) {
        showErrorAlert(i18next.t("errors.permissionsError"));
      } else if (isError5xx(error.response.status)) {
        showErrorAlert(i18next.t("errors.serverError"));
      } else if (
        isError422(error.response.status) ||
        isError400(error.response.status)
      ) {
        if (error.response?.data instanceof Blob) {
          error.response.data
            .text()
            .then((data: any) => {
              const errorData = JSON.parse(data);
              const errorMessage = errorData.error?.message;
              if (errorMessage) {
                const message = get422ErrorMessage(errorMessage);
                showErrorAlert(message);
              }
            })
            .catch((err: any) => {
              console.error("Error processing response:", err);
            });
        } else {
          const errorMessage = error?.response?.data?.error?.message;
          if (errorMessage) {
            const message = get422ErrorMessage(errorMessage) || errorMessage;
            showErrorAlert(message);
          }
        }
      } else if (isError429(error.response.status)) {
        showErrorAlert(i18next.t("errors.error429"));
      } else if (isError408(error.response.status)) {
        showErrorAlert(i18next.t("errors.error408"));
      }
      return Promise.reject(error);
    },
  );
};
