import { Box } from "@mui/material";
import React, { FC } from "react";

import { SPACING } from "../../../theme/spacing";
import { PathLink } from "../../pages/pathLink";
import Button from "../Button";
import Captcha from "../Captcha";
import Link from "../Link";
import Typography from "../Typography";
import Props from "./props";

const CaptchaConsent: FC<Props> = ({
  form,
  buttonTitle = "Create account",
  isLoading = false,
  ...rest
}: any) => {
  return (
    <>
      <Box
        style={{
          marginTop: SPACING.s_60,
          flexDirection: "column",
          display: "flex",
        }}
        {...rest}>
        <Box style={{ width: 277 }}>
          <Typography variant="overline">
            We handle your data securely & responsibly. Learn more in our
            <Link variant="h4" href={PathLink.PrivacyPolicy}>
              &nbsp; Privacy Policy
            </Link>
          </Typography>
        </Box>

        <Box style={{ marginTop: SPACING.s_32 }}>
          <Captcha form={form} />
        </Box>
      </Box>

      <Box
        style={{
          // marginTop: SPACING.s_60,
          paddingBottom: 93,
          display: "flex",
          justifyContent: "flex-end",
        }}>
        <Button typeClassName="primary" type="submit" isSpinner={isLoading}>
          {buttonTitle}
        </Button>
      </Box>
    </>
  );
};

export default CaptchaConsent;
