import { DialogActions as MUIDialogActions } from "@mui/material";
import clsx from "clsx";
import * as React from "react";
import { FC } from "react";

import Props from "./props";
import { useStyles } from "./styles";

const DialogActions: FC<Props> = ({ children, className, ...rest }) => {
  const { classes } = useStyles();
  return (
    <MUIDialogActions className={clsx(classes.root, className)} {...rest}>
      {children}
    </MUIDialogActions>
  );
};

export default DialogActions;
