import { Theme } from "@mui/material";
import { makeStyles } from "tss-react/mui";

import { COLORS } from "../../../../../../../theme/colors";

export type ClassNames =
  | "root"
  | "menuButton"
  | "avatar"
  | "iconChevronDown"
  | "menu"
  | "menuItem"
  | "navLink"
  | "navLinkMenu";

export const useStyles = makeStyles<void, ClassNames>()((theme: Theme) => ({
  root: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  menuItem: {
    // cursor: "initial",
    // "&:hover": {
    //   background: "unset",
    //   cursor: "initial",
    // },
    padding: "6px 16px",
  },
  menu: {
    minWidth: 154,
    borderRadius: 0,
    boxShadow: "0px 2px 8px 0px rgba(0, 0, 0, 0.10)",

    "& .MuiMenu-list": {
      padding: 0,
    },
  },
  navLink: {
    ...(theme.typography.body1 as any),
    color: COLORS.primary.grey40,
    textTransform: "capitalize",
    // "&:hover": {
    //   color: COLORS.primary.white,
    // },
    textDecoration: "none",
  },
  navLinkMenu: {
    color: COLORS.primary.darkGreen,

    // "&:hover": {
    //   color: COLORS.primary.green,
    //   background: "unset",
    // },
  },
  menuButton: {
    "&:hover": {
      "& > *": {
        color: "initial",
      },
      "& svg": {
        color: "initial",
      },
    },
  },
  avatar: {
    width: 40,
    height: 40,
    marginRight: 4,
    color: "initial",
  },
  iconChevronDown: {
    width: 18,
    height: 18,
    color: COLORS.primary.darkGreen,
  },
}));
