import { Theme } from "@mui/material";
import { makeStyles } from "tss-react/mui";

import { SPACING } from "../../../theme/spacing";

export type ClassNames = "root" | "header" | "headerButton";

export const useStyles = makeStyles<void, ClassNames>()((theme: Theme) => ({
  root: {},
  header: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    width: "100%",

    [theme.breakpoints.down("lg")]: {
      flexDirection: "column",
      alignItems: "flex-start",
    },
  },
  headerButton: {
    marginRight: SPACING.s_40,
    [theme.breakpoints.down("lg")]: {
      marginTop: SPACING.s_8,
      marginRight: 0,
      width: "100%",
    },
  },
}));
