import { Box, Link } from "@mui/material";
import clsx from "clsx";
import React, { FC, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { Outlet, useLocation } from "react-router-dom";

import Button from "../../atoms/Button";
import Menu from "../../atoms/Menu";
import MenuItem from "../../atoms/MenuItem";
import SvgIcon from "../../atoms/SvgIcon";
import Typography from "../../atoms/Typography";
import { PathPage } from "../../pages/pathPage";
import Props from "./props";
import { useStyles } from "./styles";

const LayoutMinimal: FC<Props> = ({ className }: Props) => {
  const { classes } = useStyles();

  return (
    <div className={clsx(classes.root, className)}>
      <Outlet />
    </div>
  );
};

const Navigation = () => {
  const { t } = useTranslation();
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const menuItems = useMemo(() => {
    return {
      caseStudies: [
        {
          label: t("BARNES Case Studies"),
          href: "https://familyofficelist.org/barnes-case-study/",
        },
        {
          label: t("Helping Capital2Market Raise $25M"),
          href: "https://familyofficelist.org/case-study-capital2market/",
        },
        {
          label: t("Creed Venture Partners"),
          href: "https://familyofficelist.org/creed-venture-partners/",
        },
        {
          label: t("Growing Conrad Family Office’s Network"),
          href: "https://familyofficelist.org/conrad-family-office/",
        },
        {
          label: t("Cody James Capital Pitched Crypto"),
          href: "https://familyofficelist.org/family-office-list-helps-cody-james-capital-pitch-crypto-to-high-net-worth-individuals/",
        },
        {
          label: t("LivBrook Jumpstarts Networking"),
          href: "https://familyofficelist.org/livbrook-jumpstartes-networking/",
        },
        {
          label: t("Verdant Frontiers"),
          href: "https://familyofficelist.org/verdant-frontiers/",
        },
        {
          label: t("Worth Avenue Yachts"),
          href: "https://familyofficelist.org/worth-avenue-yachts/",
        },
      ],
      products: {
        label: t("products"),
        href: "#",
      },
      support: {
        label: t("support"),
        href: "https://familyofficelist.org/contact/",
      },
      signIn: {
        label: t("sign in"),
        href: PathPage.SignIn,
      },
      signUp: {
        label: t("sign up"),
        href: PathPage.SignUp,
      },
    };
  }, [t]);
  const { classes } = useStyles();

  const location = useLocation();

  return (
    <Box className={classes.nav}>
      <Button
        type="submit"
        className={classes.menuButton}
        endIcon={
          <SvgIcon
            name="sortDown"
            style={{
              width: 12,
              height: 6,
              marginTop: 4,
              marginLeft: 4,
            }}
          />
        }
        typeClassName="ghost"
        onClick={handleClick}>
        <Typography variant="body1" className={classes.navLink}>
          {t("case studies")}
        </Typography>
      </Button>
      <Menu anchorEl={anchorEl} open={open} onClose={handleClose}>
        {menuItems.caseStudies.map(({ label, href }, key) => (
          <MenuItem key={key} className={clsx(classes.menu)}>
            <Link
              variant="body1"
              href={href}
              className={clsx(classes.navLink, classes.navLinkMenu)}>
              {label}
            </Link>
          </MenuItem>
        ))}
      </Menu>
      <Button type="submit" typeClassName="ghost">
        <Link
          variant="body1"
          className={classes.navLink}
          href={menuItems.products.href}>
          {menuItems.products.label}
        </Link>
      </Button>

      <Button type="submit" typeClassName="ghost">
        <Link
          variant="body1"
          className={classes.navLink}
          href={menuItems.support.href}>
          {menuItems.support.label}
        </Link>
      </Button>

      {location.pathname === PathPage.SignIn ? (
        <Button
          type="submit"
          typeClassName="secondary"
          className={clsx(classes.navButton, classes.navLink)}
          href={menuItems.signUp.href}>
          {menuItems.signUp.label}
        </Button>
      ) : (
        <Button
          type="submit"
          typeClassName="secondary"
          className={clsx(classes.navButton, classes.navLink)}
          href={menuItems.signIn.href}>
          {menuItems.signIn.label}
        </Button>
      )}
    </Box>
  );
};

export default LayoutMinimal;
