import React, { FC, useContext } from "react";
import { Navigate, Route, Routes } from "react-router-dom";

import { useIsAdmin } from "../../hooks/useIsAdmin";
import { useIsAuthorized } from "../../hooks/useIsAuthorized";
import { useIsCustomer } from "../../hooks/useIsCustomer";
import { useIsSuperAdmin } from "../../hooks/useIsSuperAdmin";
import { AlertContext } from "../atoms/Alert/AlertContextProvider";
import LayoutMain from "../layouts/LayoutMain";
import LayoutMinimal from "../layouts/LayoutMinimal";
import PageAccountDetails from "./PageAccountDetails";
import PageConfirmEmail from "./PageConfirmEmail";
import PageDashboard from "./PageDashboard";
import PageForgotPassword from "./PageForgotPassword";
import PageMyData from "./PageMyData";
import PageMyRequests from "./PageMyRequests";
import PageResetPassword from "./PageResetPassword";
import PageResource from "./PageResource";
import PageResources from "./PageResources";
import PageSavedSearch from "./PageSavedSearch";
import PageSettings from "./PageSettings";
import PageSignIn from "./PageSignIn";
import PageSignUp from "./PageSignUp";
import PageSignUpSurvey from "./PageSignUpSurvey";
import PageSubscription from "./PageSubscription";
import PageSubscriptions from "./PageSubscriptions";
import PageSupport from "./PageSupport";
import { PathPage } from "./pathPage";

const Pages: FC = () => {
  const { showAlert } = useContext(AlertContext);

  const isCustomer = useIsCustomer();
  const isAdmin = useIsAdmin();
  const isSuperAdmin = useIsSuperAdmin();

  const isLoggedIn = useIsAuthorized();

  return (
    <Routes>
      <Route element={<LayoutMinimal />}>
        <Route path={PathPage.SignUpSurvey} element={<PageSignUpSurvey />} />
        <Route path={PathPage.ConfirmEmail} element={<PageConfirmEmail />} />
      </Route>
      {!isLoggedIn && (
        <Route element={<LayoutMinimal />}>
          <Route path="*" element={<Navigate to={PathPage.SignIn} replace />} />
          <Route
            path={"/"}
            element={<Navigate to={PathPage.SignIn} replace />}
          />
          <Route path={PathPage.SignIn} element={<PageSignIn />} />
          <Route path={PathPage.SignUp} element={<PageSignUp />} />

          <Route
            path={PathPage.ForgotPassword}
            element={<PageForgotPassword />}
          />

          <Route
            path={PathPage.ResetPassword}
            element={<PageResetPassword />}
          />
        </Route>
      )}
      {isLoggedIn && (
        <Route element={<LayoutMain />}>
          {isCustomer && (
            <>
              <Route
                path="*"
                element={<Navigate to={PathPage.Overview} replace />}
              />
              <Route
                path={"/"}
                element={<Navigate to={PathPage.Overview} replace />}
              />

              <Route path={PathPage.Overview} element={<PageDashboard />} />
              <Route path={PathPage.MyData} element={<PageMyData />} />
              <Route
                path={PathPage.AccountDetails}
                element={<PageAccountDetails />}
              />

              {/*<Route*/}
              {/*  path={PathPage.ManageUsers}*/}
              {/*  element={<PageManageUsers />}*/}
              {/*/>*/}

              <Route path={PathPage.MyRequests} element={<PageMyRequests />} />
              <Route path={PathPage.Support} element={<PageSupport />} />
              <Route path={PathPage.Resources} element={<PageResources />} />
              <Route path={PathPage.Resource} element={<PageResource />} />
              <Route
                path={PathPage.SavedSearch}
                element={<PageSavedSearch />}
              />
              <Route
                path={PathPage.PaymentSuccess}
                element={<PageSubscriptions />}
              />
              <Route
                path={PathPage.PaymentCancel}
                element={<PageSubscriptions />}
              />
              <Route
                path={PathPage.Subscriptions}
                element={<PageSubscriptions />}
              />
              <Route
                path={PathPage.Subscription}
                element={<PageSubscription />}
              />
              <Route path={PathPage.Settings} element={<PageSettings />} />
            </>
          )}

          {/*{isSuperAdmin && (*/}
          {/*  <>*/}
          {/*    <Route*/}
          {/*      path={PathPage.ManageUsers}*/}
          {/*      element={<PageManageUsersAdmin />}*/}
          {/*    />*/}
          {/*    <Route path={PathPage.Overview} element={<PageOverviewAdmin />} />*/}
          {/*    <Route path={PathPage.Profile} element={<PageProfileAdmin />} />*/}
          {/*    <Route path={PathPage.Users} element={<PageUsers />} />*/}
          {/*  </>*/}
          {/*)}*/}
        </Route>
      )}

      {/*<RoutePublic*/}
      {/*  className={"no-header"}*/}
      {/*  component={PageResetPassword}*/}
      {/*  exact*/}
      {/*  layouts={LayoutMinimal}*/}
      {/*  path="/forgot-password"*/}
      {/*/>*/}
      {/*<RoutePublic*/}
      {/*  component={Page404}*/}
      {/*  exact*/}
      {/*  layouts={LayoutMinimal}*/}
      {/*  path="/not-found"*/}
      {/*/>*/}
      {/*{(superAdmin || manager) && (*/}
      {/*  <PrivateRoute*/}
      {/*    component={OcietyAdminPromotions}*/}
      {/*    exact*/}
      {/*    layouts={MainLayout}*/}
      {/*    path="/deals"*/}
      {/*  />*/}
      {/*)}*/}
      {/*<RoutePublic*/}
      {/*  component={Venues}*/}
      {/*  exact*/}
      {/*  layouts={MinimalLayout}*/}
      {/*  path="/businesses/:uuid?"*/}
      {/*/>*/}
      {/*<RoutePublic*/}
      {/*  component={() => {*/}
      {/*    window.location.href = "https://help.ociety.com/";*/}
      {/*    return null;*/}
      {/*  }}*/}
      {/*  exact*/}
      {/*  layouts={MinimalLayout}*/}
      {/*  path="/help"*/}
      {/*/>*/}

      {/*{history.location.pathname === "/venues" ? (*/}
      {/*  <Redirect to="/businesses" />*/}
      {/*) : (*/}
      {/*  <Redirect to="/not-found" />*/}
      {/*)}*/}
    </Routes>
  );
};

export default Pages;
