import { Alert as MUIAlert, AlertProps } from "@mui/material";
import React, { FC } from "react";

import { COLORS } from "../../../theme/colors";
import SvgIcon from "../SvgIcon";
import Props from "./props";
import { useStyles } from "./styles";

const Alert: FC<Props> = React.forwardRef<HTMLDivElement, AlertProps>(
  function Alert(props, ref) {
    const { classes } = useStyles();

    return (
      <MUIAlert
        iconMapping={{
          success: (
            <SvgIcon
              name="circleCheck"
              style={{ color: COLORS.primary.green }}
              width={30}
              height={30}
            />
          ),
          warning: (
            <SvgIcon
              name="warning"
              style={{ color: COLORS.secondary.red }}
              width={32}
              height={32}
            />
          ),
          error: (
            <SvgIcon
              name="warningDiamond"
              style={{ color: COLORS.primary.white }}
              width={32}
              height={32}
            />
          ),
        }}
        classes={{ root: classes.root }}
        elevation={6}
        ref={ref}
        variant="filled"
        {...props}
      />
    );
  },
);

export default Alert;
