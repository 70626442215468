import { Theme } from "@mui/material";
import { makeStyles } from "tss-react/mui";

import { COLORS } from "../../../theme/colors";

export type ClassNames = "root" | "selected";

export const useStyles = makeStyles<void, ClassNames>()((theme: Theme) => ({
  root: {
    "& .MuiAvatar-fallback": {
      color: `${COLORS.primary.white} !important`,
    },
  },
  selected: {
    border: `solid 5px ${COLORS.primary.lightGreen}`,
  },
}));
