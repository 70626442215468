import { Theme } from "@mui/material";
import { makeStyles } from "tss-react/mui";

import { ZIndexType } from "../../../@types/zIndex.type";
import { COLORS } from "../../../theme/colors";
import { SPACING } from "../../../theme/spacing";

export type ClassNames =
  | "root"
  | "title"
  | "content"
  | "actions"
  | "titleBackground"
  | "titleBackgroundWrapper"
  | "closeButton";

export const useStyles = makeStyles<void, ClassNames>()((theme: Theme) => ({
  root: {},
  title: {
    padding: SPACING.s_48,
    display: "flex",
    flexDirection: "column",
    zIndex: ZIndexType.aboveDefault,
  },
  content: {
    padding: SPACING.s_48,
    paddingBottom: 0,
  },
  actions: {
    padding: SPACING.s_48,
    justifyContent: "space-between",
    "& > div > *": {
      marginLeft: SPACING.s_24,
    },
  },
  titleBackground: {
    width: "614px",
    height: "100%",
    position: "absolute",
  },
  titleBackgroundWrapper: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: COLORS.primary.creme,
    position: "absolute",
    top: 0,
    width: "100%",
    minHeight: 180,
    height: "100%",
    zIndex: ZIndexType.default,
  },
  closeButton: {
    position: "absolute",
    right: "14px",
    top: "11px",
    zIndex: 2,
    padding: 0,

    "& .MuiSvgIcon-root": {
      color: COLORS.primary.grey40,
    },

    "&:hover": {
      "& .MuiSvgIcon-root": {
        color: COLORS.primary.grey50,
      },
    },
  },
}));
