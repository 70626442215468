import { Box } from "@mui/material";
import * as React from "react";
import { FC, Suspense } from "react";
import { useNavigate } from "react-router-dom";

import { useApiRequest } from "../../../hooks/useApiRequest";
import { API_ENDPOINTS } from "../../../middleware/api/endpoints.api";
import BoxContainer from "../../atoms/BoxContainer";
import Button from "../../atoms/Button";
import PortalHeader from "../../atoms/PortalHeader";
import PortalHeaderTablet from "../../atoms/PortalHeaderTablet";
import SvgIcon from "../../atoms/SvgIcon";
import Typography from "../../atoms/Typography";
import Props from "./props";
import { useStyles } from "./styles";

const Content = React.lazy(() => import("./Content"));
const PageAccountDetails: FC<Props> = (props: Props) => {
  return (
    <>
      <Header />
      <HeaderTablet />
      <Suspense fallback={<></>}>
        <BoxContainer style={{ width: "100%" }}>
          <Content />
        </BoxContainer>
      </Suspense>
    </>
  );
};

const ButtonManageSubscriptions = () => {
  const { classes } = useStyles();

  const navigate = useNavigate();

  const [requestState, sendRequest] = useApiRequest<any, any>(
    API_ENDPOINTS.STRIPE.MANAGE_SUBSCRIPTIONS,
    "POST",
    false,
    {
      headers: { "Content-Type": "multipart/form-data" },
    },
  );

  const onManageSubscriptions = () => {
    sendRequest([])
      .then((data) => {
        if (data?.sessionUrl) window.location.href = data.sessionUrl;
      })
      .catch((error) => {
        console.error("Error fetching requests:", error);
      });
  };

  return (
    <>
      <Button
        type="button"
        isSpinner={requestState.isLoading}
        className={classes.headerButton}
        onClick={onManageSubscriptions}
        endIcon={<SvgIcon name="calendar" />}
        typeClassName="secondary">
        Manage Subscriptions
      </Button>
    </>
  );
};
const Header = () => {
  const { classes } = useStyles();
  return (
    <PortalHeader>
      <Box className={classes.header}>
        <Typography variant="h2">Billing</Typography>
        <Box sx={{ display: { xs: "none", lg: "block" } }}>
          <ButtonManageSubscriptions />
        </Box>
      </Box>
    </PortalHeader>
  );
};

const HeaderTablet = () => {
  const { classes } = useStyles();

  return (
    <PortalHeaderTablet>
      <Box
        sx={{ display: { xs: "flex", lg: "none" } }}
        style={{ width: "100%" }}>
        <ButtonManageSubscriptions />
      </Box>
    </PortalHeaderTablet>
  );
};

export default PageAccountDetails;
