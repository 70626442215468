import { Theme } from "@mui/material";
import { makeStyles } from "tss-react/mui";

export type ClassNames = "root" | "paper";

export const useStyles = makeStyles<void, ClassNames>()((theme: Theme) => ({
  root: {
    minWidth: theme.breakpoints.values.md,
  },
  paper: {
    "& *::-webkit-scrollbar": {
      display: "none",
    },
    "&::-webkit-scrollbar": { display: "none" },
    borderRadius: 20,
    boxShadow: "none",
    maxWidth: 800,
  },
}));
