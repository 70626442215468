import { Menu as MUIMenu } from "@mui/material";
import clsx from "clsx";
import React, { FC } from "react";

import Props from "./props";
import { useStyles } from "./styles";

const Menu: FC<Props> = ({
  children,
  classes: propsClasses,
  className,
  ...rest
}) => {
  const { classes } = useStyles();
  return (
    <MUIMenu
      classes={{ root: propsClasses?.root, paper: propsClasses?.paper }}
      className={clsx(classes.root, className)}
      {...rest}>
      {children}
    </MUIMenu>
  );
};

export default Menu;
