import { Theme } from "@mui/material";
import { makeStyles } from "tss-react/mui";

import { ZIndexType } from "../../../@types/zIndex.type";
import { COLORS } from "../../../theme/colors";
import { SPACING } from "../../../theme/spacing";

export type ClassNames =
  | "root"
  | "header"
  | "notification"
  | "notificationActions"
  | "notificationIconButton"
  | "notificationContainer"
  | "footer"
  | "new"
  | "report"
  | "read"
  | "headerIcon";

export const useStyles = makeStyles<void, ClassNames>()((theme: Theme) => ({
  root: {
    width: "500px",
    boxShadow: "0px 2px 8px 0px rgba(0, 0, 0, 0.10)",
    borderRadius: "8px",
  },
  header: {
    display: "flex",
    padding: SPACING.s_16,
    justifyContent: "space-between",
    alignItems: "center",
    alignSelf: "stretch",
    borderRadius: "8px 8px 0px 0px",
    background: COLORS.primary.darkGreen,
  },
  headerIcon: {
    cursor: "pointer",
    ":hover": {
      backgroundColor: COLORS.secondary.green,
    },

    "& .MuiSvgIcon-root": {
      color: COLORS.primary.white,
    },

    "&:hover": {
      "& .MuiSvgIcon-root": {
        color: COLORS.primary.white,
      },
    },

    "&:disabled": {
      "& .MuiSvgIcon-root": {
        color: COLORS.secondary.green,
      },
    },
  },
  notification: {
    display: "flex",
    height: "64px",
    padding: "12px 16px",
    justifyContent: "space-between",
    alignItems: "center",
    alignSelf: "stretch",
    borderBottom: `1px solid ${COLORS.primary.grey20}`,
    background: COLORS.primary.white,
  },
  notificationActions: {
    top: "0px",
    position: "absolute",
    width: "100%",
    display: "none",
    height: "64px",
    padding: "12px 16px",
    justifyContent: "center",
    alignItems: "center",
    alignSelf: "stretch",
    borderBottom: `1px solid ${COLORS.primary.grey20}`,
    background: COLORS.primary.white,
    zIndex: ZIndexType.aboveDefault,
  },
  notificationContainer: {},
  footer: {
    display: "flex",
    padding: SPACING.s_16,
    flexDirection: "column",
    alignItems: "flex-end",
    gap: "10px",
    alignSelf: "stretch",
    borderRadius: "0px 0px 8px 8px",
    background: COLORS.primary.white,
  },
  notificationIconButton: {
    pointerEvents: "none",
    display: "flex",
    width: "40px",
    height: "40px",
    padding: "10px",
    justifyContent: "center",
    alignItems: "center",
    gap: "10px",
    borderRadius: "99px",
    marginRight: SPACING.s_16,
  },
  new: {
    cursor: "pointer",
    "&:hover > *[class*='-notificationActions']": {
      display: "flex",
    },

    "& *[class*='-notificationIconButton']": {
      background: COLORS.secondary.lightGreen10,
      "&  svg": {
        color: COLORS.secondary.green,
      },
    },
  },
  report: {
    cursor: "pointer",
    "&:hover > *[class*='-notificationActions']": {
      display: "flex",
    },

    "& *[class*='-notificationIconButton']": {
      background: COLORS.secondary.lightRed,
      "& svg": {
        color: COLORS.secondary.darkRed,
      },
    },
  },
  read: {
    cursor: "pointer",

    "&:hover > *[class*='-notificationActions']": {
      display: "flex",
    },

    "& > *[class*='-notification']": {
      backgroundColor: COLORS.primary.grey10,
    },

    "& *[class*='-notificationIconButton']": {
      background: COLORS.primary.grey20,
      "& svg": {
        color: COLORS.primary.grey50,
      },
    },
  },
}));
