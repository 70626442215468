import React, { FC } from "react";
import { GoogleReCaptcha } from "react-google-recaptcha-v3";
import { useController } from "react-hook-form";

import FormHelperText from "../@form/FormHelperText";
import Props from "./props";

const Captcha: FC<Props> = ({
  form: {
    formState: { errors, isSubmitting },
    register,
    control,
  },
}: any) => {
  const {
    field,
    fieldState: { error },
  } = useController({
    name: "recaptcha",
    control,
  });

  return (
    <>
      <GoogleReCaptcha
        onVerify={field.onChange}
        refreshReCaptcha={isSubmitting}
      />
      {errors.recaptcha && (
        <FormHelperText>{errors.recaptcha.message}</FormHelperText>
      )}
    </>
  );
};

export default Captcha;
