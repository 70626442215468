import { Box, IconButton } from "@mui/material";
import clsx from "clsx";
import moment from "moment/moment";
import * as React from "react";
import { FC, useCallback, useMemo } from "react";
import { useNavigate } from "react-router-dom";

import { useApiRequest } from "../../../hooks/useApiRequest";
import { Tickets } from "../../../middleware/api/customerRequests/types";
import { API_ENDPOINTS } from "../../../middleware/api/endpoints.api";
import { NotificationsEntity } from "../../../middleware/api/entities/NotificationsEntity";
import { NotificationsTypeEnum } from "../../../middleware/api/notifications/type";
import { COLORS } from "../../../theme/colors";
import { SPACING } from "../../../theme/spacing";
import { TimeNamedFormat } from "../../../utils/date.util";
import Button from "../../atoms/Button";
import Empty from "../../atoms/Empty";
import SvgIcon from "../../atoms/SvgIcon";
import Tooltip from "../../atoms/Tooltip";
import Typography from "../../atoms/Typography";
import UsePagination from "../../atoms/UsePagination";
import { PathPage } from "../../pages/pathPage";
import Props from "./props";
import { useStyles } from "./styles";

const Notifications: FC<Props> = ({
  list,
  markAsReadByIds,
  requestState,
  changePage,
}) => {
  const { classes } = useStyles();

  const isCheckDisabled = useMemo(() => {
    return !list.some(
      (notification: NotificationsEntity) => !notification.readAt,
    );
  }, [list]);

  const handlePageChange = (
    event: React.ChangeEvent<unknown>,
    newPage: number,
  ) => {
    changePage(newPage);
  };

  return (
    <div className={classes.root}>
      <Header
        isCheckDisabled={isCheckDisabled}
        markAsReadByIds={markAsReadByIds}
      />

      {list.length <= 0 ? (
        <Box style={{ height: "270px", position: "relative" }}>
          <Empty />
        </Box>
      ) : (
        <List list={list} markAsReadByIds={markAsReadByIds} />
      )}

      <div className={classes.footer}>
        {list.length > 0 && requestState?.paginationMeta?.totalPages > 1 && (
          <UsePagination
            isFrontendPagination={false}
            page={requestState.paginationMeta.page}
            totalItems={requestState.paginationMeta.total}
            itemsPerPage={requestState.paginationMeta.limit}
            onChange={handlePageChange}
          />
        )}
      </div>
    </div>
  );
};

const List = ({
  list,
  markAsReadByIds,
}: {
  list: NotificationsEntity[];
  markAsReadByIds: any;
}) => {
  const { classes } = useStyles();

  const getState = useCallback((readAt: Date | null) => {
    if (!readAt) {
      return "new";
    } else {
      return "read";
    }
  }, []);

  const [, postSendRequest] = useApiRequest<Tickets, any>("", "POST");

  const markAsRead = (id: string) => {
    postSendRequest(
      {},
      `${API_ENDPOINTS.NOTIFICATIONS.READ.replace(":notificationId", id)}`,
    )
      .then(() => {
        markAsReadByIds([id]);
      })
      .catch((error) => {
        console.error("Error fetching requests:", error);
      });
  };

  const getNotificationPath = useCallback(
    (notificationType: NotificationsTypeEnum, data: any) => {
      switch (notificationType) {
        case NotificationsTypeEnum.TICKET_REQUESTED:
          return `${PathPage.MyRequests}#tickets`;
        case NotificationsTypeEnum.TICKET_RESOLVED:
          return PathPage.MyRequests;
        case NotificationsTypeEnum.NEW_OFFICE_ADDED:
          return PathPage.SavedSearch.replace(":name", data.name);
        default:
          return "";
      }
    },
    [],
  );

  const getNotificationMessage = useCallback(
    (notificationType: NotificationsTypeEnum) => {
      switch (notificationType) {
        case NotificationsTypeEnum.TICKET_REQUESTED:
          return "Request was published";
        case NotificationsTypeEnum.TICKET_RESOLVED:
          return "Ticket was resolved";
        case NotificationsTypeEnum.NEW_OFFICE_ADDED:
          return "New office was added";
        default:
          return "Unknown notification";
      }
    },
    [],
  );

  const getNotificationIcon = useCallback(
    (notificationType: NotificationsTypeEnum) => {
      switch (notificationType) {
        case NotificationsTypeEnum.TICKET_REQUESTED:
          return "paperPlane";
        case NotificationsTypeEnum.TICKET_RESOLVED:
          return "check";
        case NotificationsTypeEnum.NEW_OFFICE_ADDED:
          return "fileAdd";
        default:
          return "check";
      }
    },
    [],
  );

  const isTicketRequested = useCallback(
    (notification: any) =>
      notification.type === NotificationsTypeEnum.TICKET_REQUESTED,
    [],
  );

  const GetIcon = useCallback(
    ({ notification }: { notification: NotificationsEntity }) => {
      return (
        <Box display="flex" style={{ marginRight: 30, alignItems: "center" }}>
          <IconButton className={classes.notificationIconButton}>
            <SvgIcon
              name={getNotificationIcon(notification.type)}
              width={24}
              height={24}
            />
          </IconButton>
          <Box
            display="flex"
            flexDirection="column"
            style={{ alignItems: "center" }}>
            <Typography variant="body1">
              {notification?.data?.title || "No title"}
            </Typography>
          </Box>
        </Box>
      );
    },
    [classes.notificationIconButton, getNotificationIcon],
  );

  const navigate = useNavigate();

  const navigateTo = (notification: any) => {
    navigate(getNotificationPath(notification.type, notification.data));
  };

  return (
    <>
      {list.map((notification: NotificationsEntity, key: number) => (
        <Box
          style={{ position: "relative" }}
          key={key}
          className={clsx(classes[getState(notification.readAt)])}>
          <div className={classes.notification}>
            <GetIcon notification={notification} />
            <Box
              display="flex"
              flexDirection="column"
              style={{ flexShrink: 0 }}>
              <Typography variant="overline">
                {moment(notification.createdAt).format(
                  TimeNamedFormat.DATE_TIME_AM_PM,
                )}
              </Typography>
              <Typography variant="overline" textAlign={"end"}>
                {getNotificationMessage(notification.type)}
              </Typography>
            </Box>
          </div>
          <div className={classes.notificationActions}>
            {getState(notification.readAt) === "new" && (
              <Button
                type="button"
                onClick={() => markAsRead(notification.id)}
                style={{
                  marginRight: SPACING.s_16,
                  visibility:
                    getState(notification.readAt) === "new"
                      ? "visible"
                      : "hidden",
                }}
                typeClassName="secondary"
                endIcon={<SvgIcon name="check" width={24} height={24} />}>
                mark as read
              </Button>
            )}
            <Button
              type="button"
              onClick={() => navigateTo(notification)}
              typeClassName="secondary"
              endIcon={
                <SvgIcon name="magnifyingGlass" width={24} height={24} />
              }>
              view
            </Button>
          </div>
        </Box>
      ))}
    </>
  );
};
const Header = ({ isCheckDisabled, markAsReadByIds }: any) => {
  const [, postSendRequest] = useApiRequest<Tickets, any>("", "POST");

  const readAll = () => {
    postSendRequest({}, `${API_ENDPOINTS.NOTIFICATIONS.READ_ALL}`)
      .then(() => {
        markAsReadByIds([], true);
      })
      .catch((error) => {
        console.error("Error fetching requests:", error);
      });
  };
  const { classes } = useStyles();
  return (
    <div className={classes.header}>
      <Typography variant="h2" style={{ color: COLORS.primary.white }}>
        Notifications
      </Typography>
      <Tooltip placement="top" title="Read all" arrow onClick={readAll}>
        <span>
          <IconButton className={classes.headerIcon} disabled={isCheckDisabled}>
            <SvgIcon name="listCheck" width={24} height={24} />
          </IconButton>
        </span>
      </Tooltip>
    </div>
  );
};

export default Notifications;
