import { Box } from "@mui/material";
import clsx from "clsx";
import React, { FC } from "react";

import SvgIcon from "../SvgIcon";
import Typography from "../Typography";
import Props from "./props";
import { useStyles } from "./styles";

const Empty: FC<Props> = ({ isCenterFlex, isCenterAbsolute, ...rest }) => {
  const { classes } = useStyles();
  return (
    <Box
      className={clsx(classes.root, {
        [classes.centerFlex]: isCenterFlex,
        [classes.centerAbsolute]: isCenterAbsolute,
      })}>
      <Box className={classes.content}>
        <SvgIcon
          name={"table"}
          style={{ color: "#C7C7C7", width: 32, height: 32 }}
        />
        <Typography variant={"overline"} style={{ marginTop: 8 }}>
          No Data yet
        </Typography>
      </Box>
    </Box>
  );
};

export default Empty;
