import { Tooltip as MUITooltip } from "@mui/material";
import clsx from "clsx";
import React, { FC, forwardRef } from "react";

import IconButton from "../IconButton";
import SvgIcon from "../SvgIcon";
import Props from "./props";
import { useStyles } from "./styles";

const Tooltip: FC<Props> = forwardRef(
  ({ children, iconName, className, classes: propsClasses, ...rest }, ref) => {
    const { classes } = useStyles();

    if (!!iconName) {
      return (
        <MUITooltip
          placement="bottom"
          classes={{ popper: clsx(classes.popper, propsClasses?.popper) }}
          enterDelay={300}
          {...rest}
          className={clsx(classes.root, className)}
          ref={ref}>
          <span>
            <IconButton style={{ marginLeft: 4, marginTop: -8 }}>
              <SvgIcon name={iconName} width={18} height={18} />
            </IconButton>
          </span>
        </MUITooltip>
      );
    }

    return (
      <MUITooltip
        placement="bottom"
        classes={{ popper: clsx(classes.popper, propsClasses?.popper) }}
        enterDelay={300}
        {...rest}
        className={clsx(classes.root, className)}
        ref={ref}>
        <span>{children}</span>
      </MUITooltip>
    );
  },
);

Tooltip.displayName = "Tooltip";

export default Tooltip;
