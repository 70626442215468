import { SvgIcon as MUISvgIcon } from "@mui/material";
import clsx from "clsx";
import React, { FC, forwardRef, useState } from "react";

import Props from "./props";
import { useStyles } from "./styles";
import { DOMAIN_ICONS } from "./type";

const SvgIcon: FC<Props> = forwardRef(
  ({ name, hoverName, className, ...rest }, ref) => {
    const { classes } = useStyles();
    const [isHovered, setIsHovered] = useState(false);

    const hoverProps = hoverName
      ? {
          onMouseEnter: () => setIsHovered(true),
          onMouseLeave: () => setIsHovered(false),
        }
      : {};
    return (
      <MUISvgIcon
        classes={{ root: classes.root }}
        className={clsx(className)}
        inheritViewBox={true}
        {...rest}
        {...hoverProps}
        ref={ref}
        component={DOMAIN_ICONS[isHovered && hoverName ? hoverName : name]}
      />
    );
  },
);

SvgIcon.displayName = "SvgIcon";

export default SvgIcon;
