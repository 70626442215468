import {
  Box,
  DialogTitle as MUIDialogTitle,
  IconButton as MUIIconButton,
} from "@mui/material";
import * as React from "react";
import { FC } from "react";

import { SPACING } from "../../../theme/spacing";
import SvgIcon from "../SvgIcon";
import Typography from "../Typography";
import Props from "./props";
import { useStyles } from "./styles";

const DialogTitle: FC<Props> = ({
  TitleBackground,
  caption = {
    title: "",
    subTitle: "",
    description: "",
  },
  onClose,
  children,
}: Props) => {
  const { classes } = useStyles();

  return (
    <Box
      style={{
        display: "flex",
        flexDirection: "column",
        position: "relative",
      }}>
      {!!TitleBackground && (
        <Box className={classes.titleBackgroundWrapper}>
          <TitleBackground className={classes.titleBackground} />
        </Box>
      )}
      <MUIDialogTitle className={classes.title} component={"div"}>
        <Typography variant="h1">{caption.title}</Typography>
        {!!caption.subTitle && (
          <Typography variant="h3" style={{ marginTop: SPACING.s_10 }}>
            {caption.subTitle}
          </Typography>
        )}
        {!!caption.description && (
          <Typography variant="body1" style={{ marginTop: SPACING.s_10 }}>
            {caption.description}
          </Typography>
        )}

        {!!children && children}
      </MUIDialogTitle>
      <MUIIconButton className={classes.closeButton} onClick={onClose}>
        <SvgIcon name="close" width={32} height={32} />
      </MUIIconButton>
    </Box>
  );
};

export default DialogTitle;
