const BASE_URL = process.env.REACT_APP_API_URL || "";
const API_VERSION = "/api/v1";

const API_ENDPOINTS = {
  FILES: {
    UPLOAD: "/files/upload",
  },
  AUTH: {
    LOGIN: "/auth/email/login",
    SIGN_UP: "/auth/email/sign-up",
    REGISTER: "/auth/email/register",
    EMAIL_VERIFICATION: "/auth/email/verification",
    EMAIL_VERIFICATION_CONFIRM: "/auth/email/verification/confirm",
    FORGOT_PASSWORD: "/auth/forgot/password",
    RESET_PASSWORD: "/auth/reset/password",
    ME: "/auth/me",
    REFRESH: "/auth/refresh",
    LOGOUT: "/auth/logout",
    SURVEY: "/auth/survey",
    PHONE_SEND_CODE: "/auth/phone/send-code",
    PHONE_VERIFY_CODE: "/auth/phone/verify-code",
  },
  USERS: {
    USERS: "/users",
    USER: "/users/{id}",
  },
  SUPPORT: {
    TICKETS: "/support/tickets",
  },
  CUSTOMER_REQUESTS: {
    ALL_FIRM_TYPES: "/customer-requests/firm-types",
    RESEARCH_REQUESTS: "/customer-requests/research-requests",
    FLAGS: "/customer-requests/flags",
  },
  BILLING: {
    TRANSACTIONS_PACKAGES: "/billing/transactions/packages",
    SUBSCRIPTIONS: "/billing/subscriptions",
    PACKAGES_CHECKOUT: "/billing/packages/checkout",
  },
  STRIPE: {
    MANAGE_SUBSCRIPTIONS: "/stripe/manage-subscriptions",
  },
  OFFICES: {
    SEARCH: "/offices/search",
    SEARCH_SAVED: "/offices/search-saved",
    SEARCH_SAVED_BY_NAME: "/offices/search-saved/:name",
    SEARCH_SAVED_ITEMS: "/offices/search-saved/items",
    SEARCH_DOWNLOAD: "/offices/search/download",
    OFFICE: "/offices/:officeId",
    STATISTICS: "/offices/statistics",
    STATISTICS_UPDATED: "/offices/statistics/updated",
    FAVORITES: "/offices/favorites",
    FAVORITES_REMOVE: "/offices/favorites/remove",
    FAVORITES_DOWNLOAD: "/offices/favorites/download",
    FAVORITES_IDS: "/offices/favorites/ids",
    NOTE_BY_OFFICE: "/offices/:officeId/note",
    NOTES_BY_OFFICE: "/offices/:officeId/notes",
    NOTES: "/offices/notes",
    FILTERS_TYPES: "/offices/filters/types",
    OFFICERS_COUNT_BY_PACKAGE: "/offices/officers/count-by-package",
  },
  NOTIFICATIONS: {
    NOTIFICATIONS: "/notifications",
    READ: "/notifications/:notificationId/read",
    READ_ALL: "/notifications/read-all",
  },
};

export { BASE_URL, API_ENDPOINTS, API_VERSION };
