import { Theme } from "@mui/material";
import { makeStyles } from "tss-react/mui";

export type ClassNames = "root";

export const useStyles = makeStyles<void, ClassNames>()((theme: Theme) => ({
  root: {
    height: "100vh",
    display: "flex",
    overflow: "hidden",
  },
}));
