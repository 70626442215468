import { AppBar as MUIAppBar, Box } from "@mui/material";
import clsx from "clsx";
import React, { FC } from "react";

import MenuAccount from "./components/MenuAccount";
import NotificationsWithIcon from "./components/NotificationsWithIcon";
import Props from "./props";
import { useStyles } from "./styles";

const Header: FC<Props> = ({ children, ...rest }) => {
  const { classes } = useStyles();
  return (
    <>
      <MUIAppBar className={clsx(classes.root)} {...rest}>
        <Box id="portal-header" style={{ display: "flex", width: "100%" }} />

        <Box className={classes.sideRight}>
          <NotificationsWithIcon />
          <MenuAccount />
        </Box>
      </MUIAppBar>
      <Box
        id="portal-header-tablet"
        style={{ display: "flex", width: "100%" }}
      />
    </>
  );
};

export default Header;
