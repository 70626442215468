import { AsYouType, parsePhoneNumber } from "libphonenumber-js";
import { mixed, ref, string } from "yup";

export const PASSWORD_REGEX = /^(?=.*\d).{8,}$/;
export const PHONE_COUNTRY = "US";

export const SIZE_FILE = 50 * 1024 * 1024;

export const FORM_VALIDATION_RULES = {
  email: string().email("Invalid email address"),
  phoneNumber: string()
    .test(
      "starts-with-plus",
      'Phone number must start with a "+" sign',
      (value) => value?.startsWith("+"),
    )
    .test("valid-country-code", "Invalid country code", function (value) {
      if (!value?.startsWith("+")) return false;

      try {
        const phoneNumber = parsePhoneNumber(value);

        return !!phoneNumber.countryCallingCode;
      } catch (error) {
        const formatter = new AsYouType();
        formatter.input(value);
        const formattedNumber = formatter.getNumber();
        if (formattedNumber) {
          return !!formattedNumber.countryCallingCode;
        } else {
          return false;
        }
      }
    })
    .test("valid-phone-number", "Invalid phone number", function (value) {
      try {
        if (!value) return false;
        const phoneNumber = parsePhoneNumber(value);
        return phoneNumber.isValid();
      } catch {
        return false;
      }
    }),
  website: string().url("Invalid Website"),
  password: string()
    .required("Password is required")
    .matches(
      PASSWORD_REGEX,
      "Password must be at least 8 characters and at least one number",
    ),
  passwordConfirm: string()
    .required("Confirm Password is required")
    .oneOf([ref("password"), ""], "Passwords must match"),
  firstName: string().max(30, "First Name must be at most 30 characters"),
  lastName: string().max(30, "Last Name must be at most 30 characters"),
  file: mixed().test("fileSize", "File size too large", (value: any) => {
    if (!value) return true;
    return value?.size <= SIZE_FILE;
  }),
  image: mixed()
    .test("fileSize", "File size too large", (value: any) => {
      if (!value) return true;
      return value?.size <= SIZE_FILE;
    })
    .test("fileType", "Invalid file type", (value: any) => {
      if (!value) return true;
      const supportedFormats = ["image/jpg", "image/jpeg", "image/png"];
      return supportedFormats.includes(value.type);
    }),
};
