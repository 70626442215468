import { Box, CircularProgress as MUICircularProgress } from "@mui/material";
import clsx from "clsx";
import React, { FC, memo } from "react";

import Props from "./props";
import { useStyles } from "./styles";

const CircularProgress: FC<Props> = memo(
  ({ isCenterAbsolute, styleWrapper, isCenterFlex, isTable, ...rest }) => {
    const { classes } = useStyles();

    return (
      <Box
        style={{ ...styleWrapper }}
        className={clsx({
          [classes.table]: isTable,
          [classes.centerFlex]: isCenterFlex,
        })}>
        <MUICircularProgress
          disableShrink
          {...rest}
          className={clsx({
            [classes.centerAbsolute]: isCenterAbsolute,
          })}
        />
      </Box>
    );
  },
);

CircularProgress.displayName = "CircularProgress";

export default CircularProgress;
