import { Theme } from "@mui/material";
import { makeStyles } from "tss-react/mui";

export type ClassNames = "root" | "content" | "centerAbsolute" | "centerFlex";

export const useStyles = makeStyles<void, ClassNames>()((theme: Theme) => ({
  root: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    position: "absolute",
    left: "50%",
    top: "50%",
    transform: "translate(-50%,-50%)",
  },
  content: {
    width: "fit-content",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  centerAbsolute: {
    position: "absolute",
    top: "50%",
    right: "50%",
    transform: "translate(50%, -50%)",
  },
  centerFlex: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
}));
