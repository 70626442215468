import { Box } from "@mui/material";
import clsx from "clsx";
import * as React from "react";
import { FC } from "react";
import { Link as RouterLink } from "react-router-dom";

import { ReactComponent as SVGBackground } from "../../../assets/images/components/templates/authorization/authorization.svg";
import { ReactComponent as SvgLogoWithTextBlack } from "../../../assets/images/logo-with-text-black.svg";
import { COLORS } from "../../../theme/colors";
import Link from "../../atoms/Link";
import { PathPage } from "../../pages/pathPage";
import Props from "./props";
import { useStyles } from "./styles";

const Authorization: FC<Props> = ({ children, className }: Props) => {
  const { classes } = useStyles();

  return (
    <Box className={clsx(classes.root, className)}>
      <Box
        style={{
          padding: "30px 92px 0 92px",
          width: "50%",
          flex: "1",
        }}
        display="flex"
        flexDirection="column">
        <Link component={RouterLink} to={PathPage.Home}>
          <SvgLogoWithTextBlack />
        </Link>
        <Box style={{ paddingTop: "104px" }}>{children}</Box>
      </Box>
      <Box
        style={{
          width: "50%",
          background: COLORS.primary.creme,
          position: "relative",
          overflow: "hidden",
        }}>
        <SVGBackground
          style={{
            position: "absolute",
            left: 0,
            top: 0,
            transform: "translate(-565px, -340px)",
          }}
        />
      </Box>
    </Box>
  );
};

export default Authorization;
