import { Checkbox as MUICheckbox } from "@mui/material";
import React, { FC, forwardRef } from "react";

import SvgIcon from "../../SvgIcon";
import Props from "./props";
import { useStyles } from "./styles";

const Checkbox: FC<Props> = forwardRef(({ ...rest }, ref) => {
  const { classes } = useStyles();
  return (
    <MUICheckbox
      disableRipple
      icon={<></>}
      checkedIcon={
        <div className={classes.checked}>
          <SvgIcon name="check" width={16} height={16} />
        </div>
      }
      classes={{ root: classes.root }}
      {...rest}
      ref={ref}
    />
  );
});

Checkbox.displayName = "Checkbox";

export default Checkbox;
