import { Theme } from "@mui/material";
import { makeStyles } from "tss-react/mui";

import { COLORS } from "../../../theme/colors";

export type ClassNames = "root" | "popper";

export const useStyles = makeStyles<void, ClassNames>()((theme: Theme) => ({
  root: {
    "& .MuiSvgIcon-root": {
      color: COLORS.primary.grey50,
    },
    "&:hover": {
      "& .MuiSvgIcon-root": {
        color: COLORS.primary.dark,
      },
    },
  },
  popper: {
    textAlign: "center",

    "& .MuiTooltip-tooltip": {
      ...(theme.typography.overline as any),
      padding: "8px 16px",
      color: COLORS.primary.white,
    },
  },
}));
