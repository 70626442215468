import { FormControlLabel as MUIFormControlLabel } from "@mui/material";
import React, { FC, forwardRef } from "react";

import Props from "./props";
import { useStyles } from "./styles";

const FormControlLabel: FC<Props> = forwardRef(({ ...rest }, ref) => {
  const { classes } = useStyles();
  return <MUIFormControlLabel className={classes.root} {...rest} ref={ref} />;
});
FormControlLabel.displayName = "FormControlLabel";
export default FormControlLabel;
