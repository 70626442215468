import { MenuItem as MUIMenuItem } from "@mui/material";
import clsx from "clsx";
import React, { FC } from "react";

import Props from "./props";
import { useStyles } from "./styles";

const MenuItem: FC<Props> = ({ className, ...rest }) => {
  const { classes } = useStyles();
  return (
    <MUIMenuItem
      className={clsx(classes.root, className)}
      disableRipple
      {...rest}
    />
  );
};

export default MenuItem;
