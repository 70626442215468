import { Theme } from "@mui/material";
import { makeStyles } from "tss-react/mui";

export type ClassNames = "root" | "main";

export const useStyles = makeStyles<void, ClassNames>()((theme: Theme) => ({
  root: {
    display: "flex",
    minHeight: "130vh",
    height: "100%",
    maxWidth: theme.breakpoints.values.breakpoint1,
    minWidth: theme.breakpoints.values.md,
  },
  main: {
    display: "flex",
    flexGrow: 1,
    padding: "35px 40px",
    flexDirection: "column",
  },
}));
