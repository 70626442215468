import { Box } from "@mui/material";
import * as React from "react";
import {
  forwardRef,
  useCallback,
  useContext,
  useEffect,
  useImperativeHandle,
  useMemo,
  useRef,
  useState,
} from "react";

import { ReactComponent as SvgDialogTitleBackground } from "../../../../assets/images/dialog-title-background.svg";
import { useApiRequest } from "../../../../hooks/useApiRequest";
import { TransactionPackages } from "../../../../middleware/api/billing/types";
import { API_ENDPOINTS } from "../../../../middleware/api/endpoints.api";
import { AlertContext } from "../../../atoms/Alert/AlertContextProvider";
import Button from "../../../atoms/Button";
import Dialog from "../../../atoms/Dialog";
import DialogActions from "../../../atoms/DialogActions";
import DialogContent from "../../../atoms/DialogContent";
import DialogTitle from "../../../atoms/DialogTitle";
import SvgIcon from "../../../atoms/SvgIcon";
import StepOne from "./components/steps";
import GeneralFamilyOfficeProfile from "./components/steps/GeneralFamilyOfficeProfile";
import SpecificFirmOrContact from "./components/steps/SpecificFirmOrContact";
import DialogResearchRequestContextProvider, {
  DialogResearchRequestContext,
} from "./context";
import { validationSchema } from "./form";
import Props from "./props";
import { useStyles } from "./styles";

export const enum ResearchRequestTypeEnum {
  specific = "specific",
  general = "general",
}

const captions = [
  {
    title: "Research Request",
    description:
      "Our research team is committed to delivering continual updates and incorporating new offices into our database. To align our research with your unique requirements, we encourage you to engage directly with our team by utilizing our research request form. Consider us as your external research partner, dedicated to fulfilling your specific Family Office prospecting needs.",
  },
  {
    title: "Request a Specific New Office or Contact",
    description:
      "Please provide as much detail as possible. However, please note that all fields are optional, so if you don't have all the information, you can still submit your request. ",
  },
  {
    title: "Request a General Profile Type",
    description:
      "Please provide as much detail as possible. However, please note that all fields are optional, so if you don't have all the information, you can still submit your request. ",
  },
];

const DialogResearchRequest = forwardRef<any, Props>((props: Props, ref) => {
  return (
    <DialogResearchRequestContextProvider>
      <Body {...props} ref={ref} />
    </DialogResearchRequestContextProvider>
  );
});

const Body = forwardRef<any, Props>(({ handleDialogData }: Props, ref) => {
  const { classes } = useStyles();

  const { showAlert } = useContext(AlertContext);

  const [firmInfo, setFirmInfo] = useState<any>(null);

  const [open, setOpen] = useState(false);

  const [selectedDialogType, setSelectedDialogType] =
    useState<ResearchRequestTypeEnum>(ResearchRequestTypeEnum.specific);

  const [postRequestState, postSendRequest] = useApiRequest<any, any>(
    API_ENDPOINTS.CUSTOMER_REQUESTS.RESEARCH_REQUESTS,
    "POST",
  );

  const [patchRequestState, patchSendRequest] = useApiRequest<any, any>(
    "",
    "PATCH",
  );

  const handleChangeDialogType = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    const value = event.target.value;
    setSelectedDialogType(value as ResearchRequestTypeEnum);
  };

  const {
    mainForm: {
      handleSubmit,
      formState: { isValid, errors },
      getValues,
      reset,
      trigger,
      setValue,
    },
  } = useContext(DialogResearchRequestContext);

  useImperativeHandle(ref, () => ({
    handleOpen(firmInfo?: { type: ResearchRequestTypeEnum; data: any }) {
      reset();
      setOpen(true);
      if (firmInfo) setFirmInfo(firmInfo);
    },
    handleClose() {
      setOpen(false);
    },
  }));

  const [activeStep, setActiveStep] = useState(0);

  const validate = async (): Promise<boolean | undefined> => {
    const step =
      selectedDialogType === ResearchRequestTypeEnum.specific
        ? "specific"
        : "general";
    try {
      await trigger(step);
      await validationSchema.validateAt(step, getValues());

      return true;
    } catch (error) {
      if (
        errors?.general?.type === "at-least-one-field" &&
        step === ResearchRequestTypeEnum.general
      ) {
        showAlert("At least one field must have a value", "error");
      }
    }
  };

  const getStepValues = useCallback(() => {
    const step =
      selectedDialogType === ResearchRequestTypeEnum.specific
        ? "specific"
        : "general";

    return getValues()[step];
  }, [getValues, selectedDialogType]);
  const handleNext = async (e: any) => {
    e.stopPropagation();
    e.preventDefault();
    if (activeStep) {
      const isValid = await validate();
      if (isValid) setActiveStep((prevActiveStep) => prevActiveStep + 1);
    } else {
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
    }
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  useEffect(() => {
    if (!!firmInfo) {
      if (firmInfo.type === ResearchRequestTypeEnum.specific) {
        setValue("specific", firmInfo);
        setSelectedDialogType(ResearchRequestTypeEnum.specific);
      } else {
        setValue("general", firmInfo);
        setSelectedDialogType(ResearchRequestTypeEnum.general);
      }

      setActiveStep(1);
    }
  }, [firmInfo, reset, setValue]);

  const handleClose = () => {
    setOpen(false);
    setActiveStep(0);
    setSelectedDialogType(ResearchRequestTypeEnum.specific);
  };

  const steps = useMemo(() => {
    const StepOneComponent = (
      <StepOne
        key={"StepOne"}
        selectedDialogType={selectedDialogType}
        handleChangeDialogType={handleChangeDialogType}
      />
    );
    if (selectedDialogType === ResearchRequestTypeEnum.general) {
      return [
        StepOneComponent,
        <GeneralFamilyOfficeProfile key={"GeneralFamilyOfficeProfile"} />,
      ];
    }
    if (selectedDialogType === ResearchRequestTypeEnum.specific) {
      return [
        StepOneComponent,
        <SpecificFirmOrContact key={"SpecificFirmOrContact"} />,
      ];
    }

    return [StepOneComponent];
  }, [selectedDialogType]);

  const caption = useMemo(() => {
    if (activeStep === 0) {
      return captions[activeStep];
    } else {
      if (selectedDialogType === ResearchRequestTypeEnum.specific) {
        return captions[1];
      } else {
        return captions[2];
      }
    }
  }, [activeStep, selectedDialogType]);

  const onClose = useCallback(() => {
    handleClose();
  }, []);

  const onApply = async () => {
    const isValid = await validate();

    if (isValid) {
      if (firmInfo) {
        patchSendRequest(
          { ...getStepValues() },
          `${API_ENDPOINTS.CUSTOMER_REQUESTS.RESEARCH_REQUESTS}/${firmInfo.id}`,
        )
          .then(() => {
            handleDialogData(undefined);
            handleClose();
          })
          .catch((error) => {
            console.error("Error fetching requests:", error);
          });
      } else {
        postSendRequest({ ...getStepValues() })
          .then(() => {
            showAlert("Research request was sent successfully", "success");
            handleDialogData(undefined);
            handleClose();
          })
          .catch((error) => {
            console.error("Error fetching requests:", error);
          });
      }
    }
  };

  return (
    <>
      <Dialog onClose={onClose} open={open} component="form">
        <PostOpen />
        <DialogTitle
          TitleBackground={SvgDialogTitleBackground}
          caption={caption}
          onClose={onClose}
        />
        <DialogContent>{steps[activeStep]}</DialogContent>

        <DialogActions style={{ justifyContent: "space-between" }}>
          <Button
            type="button"
            onClick={handleBack}
            typeClassName="ghost"
            disabled={activeStep === 0 || !!firmInfo}
            style={{
              margin: 0,
              visibility: activeStep === 0 || firmInfo ? "hidden" : "visible",
            }}>
            Back
          </Button>

          <Box display="flex">
            <Button typeClassName="secondary" onClick={onClose} type="button">
              Cancel
            </Button>
            {activeStep === 0 ? (
              <Button
                type="button"
                onClick={handleNext}
                endIcon={
                  <SvgIcon name="chevronRight" width={16} height={16} />
                }>
                continue
              </Button>
            ) : (
              <Button
                isSpinner={
                  patchRequestState.isLoading || postRequestState.isLoading
                }
                onClick={onApply}
                type="button"
                endIcon={
                  <SvgIcon name="chevronRight" width={16} height={16} />
                }>
                submit
              </Button>
            )}
          </Box>
        </DialogActions>
      </Dialog>
    </>
  );
});

const PostOpen = () => {
  const { setFirmTypes, firmTypes } = useContext(DialogResearchRequestContext);

  const [, sendRequest] = useApiRequest<TransactionPackages[], any>(
    API_ENDPOINTS.CUSTOMER_REQUESTS.ALL_FIRM_TYPES,
    "GET",
    true,
  );

  const requestSentRef = useRef(false);

  const getRequests = useCallback(() => {
    sendRequest()
      .then((data) => {
        setFirmTypes(data);
      })
      .catch((error) => {
        console.error("Error fetching requests:", error);
      });
  }, [setFirmTypes, sendRequest]);

  useEffect(() => {
    if (firmTypes) return;
    if (requestSentRef.current) return;

    requestSentRef.current = true;
    getRequests();
  }, [firmTypes, getRequests]);

  return <></>;
};
Body.displayName = "Body";
DialogResearchRequest.displayName = "DialogResearchRequest";

export default DialogResearchRequest;
