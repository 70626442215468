import { Badge as MUIBadge } from "@mui/material";
import React, { FC } from "react";

import Props from "./props";
import { useStyles } from "./styles";

const Badge: FC<Props> = ({ children, ...rest }) => {
  const { classes } = useStyles();
  return (
    <MUIBadge classes={{ badge: classes.badge, root: classes.root }} {...rest}>
      {children}
    </MUIBadge>
  );
};

export default Badge;
