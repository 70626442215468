import { IconButton as MUIIconButton } from "@mui/material";
import clsx from "clsx";
import React, { FC, forwardRef } from "react";

import SvgIcon from "../SvgIcon";
import Props from "./props";
import { useStyles } from "./styles";

const IconButton: FC<Props> = forwardRef(
  (
    {
      children,
      name = "warning",
      typeClassName = "default",
      className,
      propsSvgIcon,
      ...rest
    },
    ref,
  ) => {
    const { classes } = useStyles();

    return (
      <MUIIconButton
        disableRipple
        {...rest}
        className={clsx(classes.root, classes[typeClassName], className)}
        ref={ref}>
        {children || <SvgIcon {...propsSvgIcon} name={name} />}
      </MUIIconButton>
    );
  },
);

IconButton.displayName = "IconButton";
export default IconButton;
