import { Box } from "@mui/material";
import * as React from "react";
import { FC, useContext, useEffect, useRef } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";

import { useApiRequest } from "../../../hooks/useApiRequest";
import { API_ENDPOINTS } from "../../../middleware/api/endpoints.api";
import { AlertContext } from "../../atoms/Alert/AlertContextProvider";
import Typography from "../../atoms/Typography";
import Authorization from "../../templates/Authorization";
import Props from "./props";
import { useStyles } from "./styles";

const PageConfirmEmail: FC<Props> = (props: Props) => {
  const { classes } = useStyles();

  const [searchParams] = useSearchParams();
  const hash = searchParams.get("hash");
  const navigate = useNavigate();

  const { showAlert } = useContext(AlertContext);

  const [requestState, sendRequest] = useApiRequest<any, any>(
    API_ENDPOINTS.AUTH.EMAIL_VERIFICATION_CONFIRM,
    "POST",
  );

  const requestSentRef = useRef(false);

  useEffect(() => {
    if (!hash) return;
    if (requestSentRef.current) return;
    requestSentRef.current = true;
    sendRequest({ hash })
      .then(() => {
        showAlert("Email was confirmed!", "success");
        setTimeout(() => {
          navigate("/");
        }, 3000);
      })
      .catch((error) => {
        showAlert("Error verification email, please contact us!", "error");
        console.error("Error fetching requests:", error);
      });
  }, [hash, navigate, sendRequest, showAlert]);

  useEffect(() => {
    if (!hash) {
      navigate("/");
    }
  }, [hash, navigate]);

  return (
    <Authorization>
      <Box>
        <Box className={classes.sectionTitle}>
          <Typography variant="h1">Email Confirmation</Typography>
          <Typography
            variant="body1"
            style={{ marginTop: 10, display: "flex" }}>
            Wait a moment while we confirm your new email
          </Typography>
        </Box>
      </Box>
    </Authorization>
  );
};

export default PageConfirmEmail;
