import { Theme } from "@mui/material";
import { makeStyles } from "tss-react/mui";

import { COLORS } from "../../../theme/colors";

export type ClassNames = "root" | "paper" | "chip";

const chip = {
  border: "none",
  backgroundColor: "unset",
  height: 20,
};
export const useStyles = makeStyles<void, ClassNames>()((theme: Theme) => ({
  root: {
    ...(theme.typography.body1 as any),
    "& .MuiChip-label": {
      ...(theme.typography.body1 as any),
      paddingLeft: 0,
    },
    "& .MuiInputLabel-root": {
      color: COLORS.primary.grey50,
    },
    "& .MuiInputBase-root:after": {
      borderWidth: "0.5px !important",
      borderColor: COLORS.primary.green,
      borderStyle: "solid",
    },
    "& .MuiInputBase-root:before": {
      borderBottomWidth: 1,
      borderColor: COLORS.primary.grey30,
      borderBottomStyle: "solid",
    },

    "& .MuiInputBase-root": {
      "& .MuiSvgIcon-root": {
        width: 18,
        height: 18,
        color: COLORS.primary.grey40,

        "&:hover": {
          color: COLORS.primary.darkGreen,
        },
      },
    },

    "& .MuiAutocomplete-endAdornment": {
      "& .MuiSvgIcon-root": {
        width: 18,
        height: 18,
        color: COLORS.primary.dark,
      },
    },

    "&:hover": {
      "& .MuiAutocomplete-endAdornment": {
        "& .MuiSvgIcon-root": {
          color: COLORS.primary.darkGreen,
        },
      },
    },

    "& .MuiInputBase-root:hover:not(.Mui-disabled, .Mui-error)::before": {
      borderBottomWidth: 1,
      borderColor: COLORS.primary.green,
      borderBottomStyle: "solid",
    },
  },
  paper: {
    borderRadius: "unset",
    boxShadow: "0px 3px 4px 0px rgba(0, 0, 0, 0.07)",

    "& .MuiAutocomplete-listbox .MuiAutocomplete-option": {
      minHeight: 40,
    },

    "& .MuiAutocomplete-listbox .MuiAutocomplete-option.Mui-focused": {
      backgroundColor: COLORS.primary.grey10,
    },
    "& .MuiAutocomplete-listbox .MuiAutocomplete-option:active": {
      backgroundColor: COLORS.secondary.green,
      color: COLORS.primary.white,
    },
    '& .MuiAutocomplete-listbox .MuiAutocomplete-option[aria-selected="true"]':
      {
        backgroundColor: COLORS.primary.green,
        color: COLORS.primary.white,
      },
    '& .MuiAutocomplete-listbox .MuiAutocomplete-option[aria-selected="true"].Mui-focused':
      {
        backgroundColor: COLORS.primary.darkGreen,
        color: COLORS.primary.white,
      },
  },
  chip,
}));
