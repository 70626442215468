import {
  IconButton,
  InputAdornment,
  TextField as MUITextField,
} from "@mui/material";
import clsx from "clsx";
import React, { FC, forwardRef, useState } from "react";

import { ReactComponent as IconEye } from "../../../assets/icons/eye.svg";
import { ReactComponent as IconEyeSlash } from "../../../assets/icons/eye-slash.svg";
import { COLORS } from "../../../theme/colors";
import Props from "./props";
import { useStyles } from "./styles";

const TextFieldPassword: FC<Props> = forwardRef(
  ({ className, ...rest }, ref) => {
    const { classes } = useStyles();
    const [showPassword, setShowPassword] = useState(false);
    const handleClickShowPassword = () => {
      setShowPassword((show) => !show);
    };

    const handleMouseDownPassword = (
      event: React.MouseEvent<HTMLButtonElement>,
    ) => {
      event.preventDefault();
    };
    return (
      <MUITextField
        label="Label text"
        variant="standard"
        type={showPassword ? "text" : "password"}
        ref={ref}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <IconButton
                onClick={handleClickShowPassword}
                onMouseDown={handleMouseDownPassword}
                edge="end">
                {showPassword ? (
                  <IconEye
                    width={18}
                    height={18}
                    style={{ color: COLORS.primary.grey50 }}
                  />
                ) : (
                  <IconEyeSlash
                    width={18}
                    height={18}
                    style={{ color: COLORS.primary.grey50 }}
                  />
                )}
              </IconButton>
            </InputAdornment>
          ),
        }}
        rows={4}
        className={clsx(classes.root, className)}
        {...rest}
      />
    );
  },
);

TextFieldPassword.displayName = "TextFieldPassword";

export default TextFieldPassword;
