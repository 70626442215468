export const basename = (path: string | undefined) => {
  if (!path) {
    return "";
  }
  return path.split(/[\\/]/).pop() || "";
};

export function extractHostname(url: string | null): string {
  if (!url) return "";

  let hostname = url.trim();

  const wwwPresent = /^(?:\w+:?\/\/)?www\./i.test(hostname);
  hostname = hostname.replace(/^(?:\w+:?\/\/)?/i, "");

  hostname = hostname.replace(/^\.+|\.+$/g, "");

  hostname = hostname.split(/\s+/).pop() || "";

  hostname = hostname.split(/[/?#]/)[0];

  hostname = hostname.split(":")[0];

  if (wwwPresent && !hostname.startsWith("www.")) {
    hostname = "www." + hostname;
  }

  return hostname.includes(".") ? hostname.toLowerCase() : "";
}

export function generateExcelFilename(prefix: string): string {
  const currentDate = new Date();
  const formattedDate = `${currentDate.getFullYear()}${(
    currentDate.getMonth() + 1
  )
    .toString()
    .padStart(2, "0")}${currentDate.getDate().toString().padStart(2, "0")}`;
  return `${prefix}_${formattedDate}.xlsx`;
}
