import { DialogContent as MUIDialogContent } from "@mui/material";
import clsx from "clsx";
import * as React from "react";
import { FC } from "react";

import Props from "./props";
import { useStyles } from "./styles";

const DialogContent: FC<Props> = ({ children, className, ...rest }: Props) => {
  const { classes } = useStyles();

  return (
    <MUIDialogContent className={clsx(classes.root, className)} {...rest}>
      {children}
    </MUIDialogContent>
  );
};

export default DialogContent;
