import { Theme } from "@mui/material";
import { makeStyles } from "tss-react/mui";

import { ZIndexType } from "../../../../../@types/zIndex.type";
import { SPACING } from "../../../../../theme/spacing";

export type ClassNames = "root" | "sideRight";

export const useStyles = makeStyles<void, ClassNames>()((theme: Theme) => ({
  root: {
    height: 40,
    backgroundColor: "unset",
    boxShadow: "none",
    display: "flex",
    alignItems: "center",
    flexDirection: "row",
    justifyContent: "space-between",
    position: "relative",
    zIndex: ZIndexType.aboveDefault,
  },
  sideRight: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    "& > *": {
      marginRight: `${SPACING.s_30}px !important`,
    },
    "& > *:last-child": {
      marginRight: `0 !important`,
    },
  },
}));
