import { Theme } from "@mui/material";
import { makeStyles } from "tss-react/mui";

import { COLORS } from "../../../theme/colors";

export type ClassNames =
  | "root"
  | "ul"
  | "button"
  | "buttonPrevNext"
  | "buttonNext"
  | "buttonPrev"
  | "buttonHover"
  | "buttonSelected";

export const useStyles = makeStyles<void, ClassNames>()((theme: Theme) => ({
  root: {},
  ul: {
    listStyle: "none",
    padding: 0,
    margin: 0,
    display: "flex",
  },
  buttonHover: {},
  button: {
    ...(theme.typography.overline as any),
    display: "flex",
    height: "42px",
    width: "42px",
    padding: "8px 12px",
    justifyContent: "center",
    alignItems: "center",
    border: `1px solid ${COLORS.primary.grey30}`,
    borderLeft: "none",
    gap: "36px",
    backgroundColor: COLORS.primary.white,

    color: COLORS.primary.grey50,

    "&:hover": {
      cursor: "pointer",
      background: COLORS.primary.grey10,
    },

    "&:active": {
      cursor: "pointer",

      color: COLORS.primary.white,
      backgroundColor: COLORS.secondary.green,
      borderColor: COLORS.secondary.green,
    },
    "&:disabled": {
      pointerEvents: "none",
      cursor: "default",
      // backgroundColor: DOMAIN_COLORS.primary.grey20,
      // color: DOMAIN_COLORS.primary.grey40,
      // borderColor: DOMAIN_COLORS.primary.grey20,
    },
  },
  buttonPrevNext: {
    textTransform: "capitalize",
    padding: "8px 28px",
    width: "auto",
  },
  buttonNext: {
    borderRadius: "0px 4px 4px 0px",
    width: "auto",
  },
  buttonPrev: {
    borderRadius: "4px 0px 0px 4px",
    borderLeft: `1px solid ${COLORS.primary.grey30}`,
    width: "auto",
  },
  buttonSelected: {
    color: COLORS.primary.white,
    backgroundColor: COLORS.primary.green,
    borderColor: COLORS.primary.green,

    "&:hover": {
      color: COLORS.primary.white,
      backgroundColor: COLORS.primary.green,
      borderColor: COLORS.primary.green,
    },
  },
}));
