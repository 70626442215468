import { Link as MUILink, LinkProps } from "@mui/material";
import clsx from "clsx";
import React, { FC, forwardRef } from "react";

import Props from "./props";
import { useStyles } from "./styles";

const Link: FC<Props> = forwardRef(
  ({ children, onClick, disabled, href, ...rest }, ref) => {
    const { classes } = useStyles();

    const handleClick = (
      event: React.MouseEvent<HTMLAnchorElement, MouseEvent>,
    ) => {
      if (disabled) {
        event.preventDefault();
        return;
      }
      if (onClick) {
        onClick(event);
      }
    };

    const linkProps: Partial<LinkProps> = {
      className: clsx(classes.root, { [classes.disabled]: disabled }),
      onClick: handleClick,
      ...rest,
    };

    if (href) {
      linkProps.href = href;
      linkProps.target = "_blank";
      linkProps.rel = "noopener noreferrer";
    }

    return (
      <MUILink {...linkProps} {...rest}>
        {children}
      </MUILink>
    );
  },
);

Link.displayName = "Link";

export default Link;
