import { Theme } from "@mui/material";
import { makeStyles } from "tss-react/mui";

import { COLORS } from "../../../theme/colors";

export type ClassNames = "root" | "badge";

export const useStyles = makeStyles<void, ClassNames>()((theme: Theme) => ({
  root: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  badge: {
    ...(theme.typography.caption as any),
    borderRadius: "90px",
    background: COLORS.primary.orange,
    color: COLORS.primary.white,
    fontWeight: "500",
    bottom: "-15px",
    top: "auto",
    right: "auto",
  },
}));
