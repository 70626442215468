import { Theme } from "@mui/material";
import { makeStyles } from "tss-react/mui";

import { COLORS } from "../../../theme/colors";

export type ClassNames = "root" | "circle" | "default";

export const useStyles = makeStyles<void, ClassNames>()((theme: Theme) => ({
  default: {
    width: 24,
    height: 24,
    color: COLORS.primary.dark,
    "&:hover": {
      cursor: "pointer",
    },
  },
  root: {
    padding: 0,
    marginRight: 0,
  },
  circle: {
    width: "40px",
    height: "40px",

    borderRadius: "99px",
    background: COLORS.primary.white,
    boxShadow: "0px 2px 4px 0px rgba(0, 0, 0, 0.15)",

    "&:hover": {
      background: COLORS.primary.grey10,
    },
    "&:active": {
      background: COLORS.primary.grey10,
    },
    "&.Mui-disabled": {
      background: COLORS.primary.grey20,
    },

    "& > svg": {
      width: 24,
      height: 24,
      color: COLORS.primary.dark,
    },
  },
}));
