import { Box as MUIBox } from "@mui/material";
import clsx from "clsx";
import React, { FC } from "react";

import Props from "./props";
import { useStyles } from "./styles";

const BoxContainer: FC<Props> = ({ children, className, ...rest }) => {
  const { classes } = useStyles();
  return (
    <MUIBox className={clsx(classes.root, className)} {...rest}>
      {children}
    </MUIBox>
  );
};

export default BoxContainer;
