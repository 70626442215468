import usePagination from "@mui/material/usePagination";
import { UsePaginationItem } from "@mui/material/usePagination/usePagination";
import clsx from "clsx";
import React, { FC } from "react";

import Props from "./props";
import { useStyles } from "./styles";

const UsePagination: FC<Props> = ({
  count,
  page,
  onChange,
  isFrontendPagination = true,
  totalItems,
  itemsPerPage = 10,
  ...rest
}) => {
  const { classes } = useStyles();

  const paginationProps = isFrontendPagination
    ? { count, page, onChange }
    : {
        count: Math.ceil((totalItems || 0) / itemsPerPage),
        page,
        onChange,
      };

  const { items } = usePagination({
    ...paginationProps,
    siblingCount: 0,
  });

  return (
    <nav>
      <ul className={classes.ul}>
        {items.map(({ page, type, selected, ...item }, index) => {
          let children = null;
          if (type === "start-ellipsis" || type === "end-ellipsis") {
            children = (
              <Number {...item} disabled>
                …
              </Number>
            );
          } else if (type === "page") {
            children = (
              <Number selected={selected} {...item}>
                {page}
              </Number>
            );
          } else {
            children = <PrevNext type={type} {...item} />;
          }
          return <li key={index}>{children}</li>;
        })}
      </ul>
    </nav>
  );
};

const Number = ({ children, selected, ...rest }: any) => {
  const { classes } = useStyles();
  return (
    <button
      type="button"
      className={clsx(classes.button, selected ? classes.buttonSelected : {})}
      {...rest}>
      {children}
    </button>
  );
};

const PrevNext = ({ type, ...rest }: Partial<UsePaginationItem>) => {
  const { classes } = useStyles();
  return (
    <button
      type="button"
      className={clsx(
        type === "previous" ? classes.buttonPrev : classes.buttonNext,
        classes.button,
        classes.buttonPrevNext,
      )}
      {...rest}>
      {type}
    </button>
  );
};

export default UsePagination;
