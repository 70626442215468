import { Box } from "@mui/material";
import MuiDrawer from "@mui/material/Drawer";
import IconButton from "@mui/material/IconButton";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import { CSSObject, styled, Theme } from "@mui/material/styles";
import clsx from "clsx";
import * as React from "react";
import { FC } from "react";
import { useLocation, useNavigate } from "react-router-dom";

import { ReactComponent as SvgLogo } from "../../../assets/images/components/atoms/sidebar/logo.svg";
import { ReactComponent as SvgLogoWithText } from "../../../assets/images/components/atoms/sidebar/logo-with-text-white.svg";
import { COLORS } from "../../../theme/colors";
import { SPACING } from "../../../theme/spacing";
import SvgIcon from "../../atoms/SvgIcon";
import { PathPage } from "../../pages/pathPage";
import Props from "./props";
import { useStyles } from "./styles";

const drawerWidth = 216;
const openedMixin = (theme: Theme): CSSObject => ({
  width: drawerWidth,
  // transition: theme.transitions.create("width", {
  //   easing: theme.transitions.easing.sharp,
  //   duration: theme.transitions.duration.enteringScreen,
  // }),
  overflow: "visible",
  marginRight: 40,
});

const closedMixin = (theme: Theme): CSSObject => ({
  // transition: theme.transitions.create("width", {
  //   easing: theme.transitions.easing.sharp,
  //   duration: theme.transitions.duration.leavingScreen,
  // }),
  width: 100,
  overflow: "visible",
});

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-end",
  ...theme.mixins.toolbar,
}));

const muiDrawerPaper = {
  backgroundColor: COLORS.primary.darkGreen,
  display: "flex",
  flexDirection: "column",
  alignItems: "flex-start",
  flex: "1 0 0",
};

const DrawerStyle = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: "nowrap",
  boxSizing: "border-box",
  ...(open && {
    ...openedMixin(theme),
    "& .MuiDrawer-paper": {
      ...openedMixin(theme),
      ...muiDrawerPaper,
      padding: "36px 0 0 40px",
      alignItems: "flex-start",
      overflow: "visible",
    },
  }),
  ...(!open && {
    ...closedMixin(theme),
    "& .MuiDrawer-paper": {
      ...closedMixin(theme),
      ...muiDrawerPaper,
      padding: "36px 0",
      alignItems: "center",
      overflow: "visible",
    },
  }),
}));

const Sidebar: FC<Props> = ({ navigationList, ...rest }) => {
  const { classes } = useStyles();
  const [open, setOpen] = React.useState(true);

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  const location = useLocation();

  const navigate = useNavigate();

  return (
    <Box style={{ position: "relative" }}>
      <DrawerStyle variant="permanent" open={open}>
        <DrawerHeader className={classes.drawerHeader}>
          <IconButton
            className={classes.buttonLogo}
            disableRipple
            onClick={() => navigate(PathPage.Overview)}>
            {open ? <SvgLogoWithText /> : <SvgLogo />}
          </IconButton>
          <IconButton
            disableRipple
            className={classes.buttonOpen}
            onClick={open ? handleDrawerClose : handleDrawerOpen}>
            {open ? (
              <SvgIcon name="chevronLeft" className={classes.buttonOpenSvg} />
            ) : (
              <SvgIcon name="chevronRight" className={classes.buttonOpenSvg} />
            )}
          </IconButton>
        </DrawerHeader>
        <List
          className={classes.list}
          style={open ? { marginLeft: -SPACING.s_16 } : {}}>
          {navigationList.map(({ name, icon, page }: any, index) => (
            <ListItem disablePadding onClick={() => navigate(page)} key={name}>
              <ListItemButton
                className={clsx(
                  classes.listItemButton,
                  location.pathname === page
                    ? classes.listItemButtonActive
                    : "",
                )}
                disableRipple
                style={
                  open
                    ? {}
                    : { width: 48, height: 48, justifyContent: "center" }
                }>
                <ListItemIcon className={classes.listItemIcon}>
                  <SvgIcon name={icon} className={classes.svgIcon} />
                </ListItemIcon>
                <ListItemText
                  className={classes.listItemText}
                  primary={name}
                  sx={{ display: open ? "flex" : "none" }}
                />
              </ListItemButton>
            </ListItem>
          ))}
        </List>
      </DrawerStyle>
    </Box>
  );
};

export default Sidebar;
