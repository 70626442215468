import { Box } from "@mui/material";
import * as React from "react";
import { FC, Suspense, useRef } from "react";

import PortalHeader from "../../atoms/PortalHeader";
import Typography from "../../atoms/Typography";
import { DialogRef } from "./components/MyProfile";
import PageContextProvider from "./context";
import Props from "./props";

const Content = React.lazy(() => import("./Content"));

const PageSettings: FC<Props> = (props: Props) => {
  return (
    <>
      <Header />
      <PageContextProvider>
        <Suspense fallback={<></>}>
          <Content />
        </Suspense>
      </PageContextProvider>
    </>
  );
};

const Header = () => {
  const dialogRef = useRef<DialogRef>(null);

  const openDialog = () => {
    dialogRef.current?.handleOpen();
  };

  return (
    <PortalHeader>
      <Box
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          width: "100%",
        }}>
        <Typography variant="h2">Settings</Typography>
      </Box>
    </PortalHeader>
  );
};

export default PageSettings;
