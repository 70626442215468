import { Theme } from "@mui/material";
import { makeStyles } from "tss-react/mui";

import { COLORS } from "../../../theme/colors";
import { SPACING } from "../../../theme/spacing";

export type ClassNames = "root";

export const useStyles = makeStyles<void, ClassNames>()((theme: Theme) => ({
  root: {
    backgroundColor: COLORS.primary.white,
    padding: SPACING.s_30,
    borderRadius: SPACING.s_16,
  },
}));
