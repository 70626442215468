import { Theme } from "@mui/material";
import { makeStyles } from "tss-react/mui";

import { COLORS } from "../../../../theme/colors";

export type ClassNames = "root" | "checked";

export const useStyles = makeStyles<void, ClassNames>()((theme: Theme) => ({
  root: {
    padding: 0,
    marginRight: 14,

    minWidth: 20,
    minHeight: 20,

    width: 20,
    height: 20,
    border: `1px solid ${COLORS.primary.grey40}`,
    borderRadius: 3,

    "&:hover": {
      backgroundColor: "unset",
      border: `1px solid ${COLORS.primary.green}`,
    },

    "&.Mui-disabled": {
      border: `1px solid ${COLORS.primary.grey40}`,
      backgroundColor: COLORS.primary.grey20,
    },

    "&.Mui-checked": {
      border: `1px solid ${COLORS.primary.green}`,
      color: COLORS.primary.white,
      backgroundColor: COLORS.primary.green,
    },

    "&.MuiCheckbox-indeterminate": {
      border: "none",
    },
  },
  checked: {
    display: "flex",

    "& .MuiSvgIcon-root": {
      color: COLORS.primary.white,
      width: 16,
      height: 16,
    },
  },
}));
