import { Theme } from "@mui/material";
import { makeStyles } from "tss-react/mui";

import { SPACING } from "../../../theme/spacing";

export type ClassNames =
  | "root"
  | "section"
  | "sectionTitle"
  | "sectionContent"
  | "sectionAboutContent";

export const useStyles = makeStyles<void, ClassNames>()((theme: Theme) => ({
  root: {},
  section: {},
  sectionTitle: {
    marginBottom: SPACING.s_40,
  },
  sectionContent: {
    display: "flex",
    flexDirection: "column",

    "& > *": {
      marginTop: `${SPACING.s_16}px !important`,
    },

    "& > :first-of-type": {
      marginTop: `0 !important`,
    },
  },

  sectionAboutContent: {
    display: "flex",
    flexDirection: "column",

    "& > *": {
      marginTop: `${SPACING.s_60}px`,
    },
  },
}));
