import { Theme } from "@mui/material";
import { makeStyles } from "tss-react/mui";

export type ClassNames = "root" | "centerAbsolute" | "centerFlex" | "table";

export const useStyles = makeStyles<void, ClassNames>()((theme: Theme) => ({
  root: {},
  centerAbsolute: {
    position: "absolute",
    top: "50%",
    right: "50%",
    transform: "translate(50%, -50%)",
  },
  centerFlex: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  table: {
    height: "270px",
  },
}));
