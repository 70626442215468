import { FormHelperText as MUIFormHelperText } from "@mui/material";
import React, { FC } from "react";

import Props from "./props";
import { useStyles } from "./styles";

const FormHelperText: FC<Props> = ({ children, ...rest }) => {
  const { classes } = useStyles();
  return (
    <MUIFormHelperText className={classes.root} {...rest}>
      {children}
    </MUIFormHelperText>
  );
};

export default FormHelperText;
