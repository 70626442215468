import { Avatar as MUIAvatar } from "@mui/material";
import clsx from "clsx";
import React, { FC } from "react";

import Props from "./props";
import { useStyles } from "./styles";

const Avatar: FC<Props> = ({ children, className, selected, ...rest }) => {
  const { classes } = useStyles();
  return (
    <MUIAvatar
      className={clsx(
        classes.root,
        className,
        selected ? classes.selected : {},
      )}
      {...rest}>
      {children}
    </MUIAvatar>
  );
};

export default Avatar;
