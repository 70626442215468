import { Theme } from "@mui/material";
import { makeStyles } from "tss-react/mui";

import { COLORS } from "../../../../theme/colors";

export type ClassNames = "root";

export const useStyles = makeStyles<void, ClassNames>()((theme: Theme) => ({
  root: {
    "& .MuiFormLabel-root": {
      marginTop: -16,
    },
    "& .MuiFormLabel-root+.MuiInputBase-root": {
      marginTop: 0,
    },

    // "& .MuiInputBase-multiline": {
    //   minHeight: 84,
    // },

    "& .MuiFormLabel-root.MuiInputLabel-root": {
      color: COLORS.primary.grey50,
    },
    "& .MuiInputBase-root:after": {
      borderWidth: 1,
      borderColor: COLORS.primary.green,
    },
    "& .MuiInputBase-root:before": {
      borderBottomWidth: 1,
      borderColor: COLORS.primary.grey30,
      borderBottomStyle: "solid",
    },
    "& .MuiInputBase-root:hover:not(.Mui-disabled, .Mui-error)::before": {
      borderBottomWidth: 1,
      borderColor: COLORS.primary.green,
      borderBottomStyle: "solid",
    },

    "& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline": {
      borderColor: COLORS.primary.green,
    },

    "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
      borderWidth: 1,
      borderColor: COLORS.primary.green,
      borderBottomStyle: "solid",
    },

    "& .MuiFormLabel-asterisk": {
      color: COLORS.secondary.red,
    },
    "& .MuiInputBase-inputMultiline": {
      resize: "none" as any,
    },
  },
}));
