import { Theme } from "@mui/material";
import { makeStyles } from "tss-react/mui";

import { SPACING } from "../../../theme/spacing";

export type ClassNames = "root" | "form" | "uploadFilesRoot" | "uploadFilesBox";

export const useStyles = makeStyles<void, ClassNames>()((theme: Theme) => ({
  root: {},
  form: {
    "& > *": {
      marginTop: `${SPACING.s_48}px !important`,
    },

    "& > :first-of-type": {
      marginTop: `30px !important`,
    },
  },
  uploadFilesRoot: { maxWidth: 260 },
  uploadFilesBox: { maxHeight: 140 },
}));
