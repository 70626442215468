import { Theme } from "@mui/material";
import { makeStyles } from "tss-react/mui";

import { COLORS } from "../../../theme/colors";

export type ClassNames = "root" | "disabled";

export const useStyles = makeStyles<void, ClassNames>()((theme: Theme) => ({
  root: {
    wordBreak: "break-all",
    width: "fit-content",
    textDecoration: "unset",
    "&.MuiTypography-h4": {},
    "&:hover": {
      cursor: "pointer !important",
    },
  },
  disabled: {
    pointerEvents: "none",
    cursor: "default !important",
    color: COLORS.primary.grey40,
    "&:hover": {
      cursor: "default !important",
      textDecoration: "none",
    },
  },
}));
