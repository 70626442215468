import { Theme } from "@mui/material";
import { makeStyles } from "tss-react/mui";

import { ZIndexType } from "../../../@types/zIndex.type";
import { SPACING } from "../../../theme/spacing";

export type ClassNames = "root" | "alertsContainer";

export const useStyles = makeStyles<void, ClassNames>()((theme: Theme) => ({
  root: {
    display: "flex",
    padding: "24px 32px",
    alignItems: "center",
    flexShrink: 0,
    borderRadius: "10px",
    boxShadow: "0px 3px 4px 0px rgba(0, 0, 0, 0.07)",

    "& .MuiAlert-message": {
      ...(theme.typography.body1 as any),
      color: "inherit",
      padding: 0,
    },
    "& .MuiAlert-icon": {
      marginRight: SPACING.s_12,
      padding: 0,
    },
  },
  alertsContainer: {
    position: "fixed",
    zIndex: ZIndexType.alerts,
    top: 25,
    right: 40,
    "& > *": {
      marginTop: SPACING.s_10,
    },
    "& > *:first-of-type": {
      marginTop: 0,
    },
  },
}));
