import { Theme } from "@mui/material";
import { makeStyles } from "tss-react/mui";

import { SPACING } from "../../../theme/spacing";

export type ClassNames = "root";

export const useStyles = makeStyles<void, ClassNames>()((theme: Theme) => ({
  root: { padding: `0 ${SPACING.s_48}px` },
}));
