import { Typography as MUITypography } from "@mui/material";
import clsx from "clsx";
import React, { FC, forwardRef } from "react";

import LinesEllipsis from "./components/reactLinesEllipsis";
import Props from "./props";
import { useStyles } from "./styles";

const Typography: FC<Props> = forwardRef(
  ({ className, children, isEllipsis, ellipsis, ...rest }, ref) => {
    const { classes } = useStyles();

    if (ellipsis) {
      return (
        <MUITypography
          className={clsx(classes.root, className, {
            [classes.ellipsis]: isEllipsis,
          })}
          ref={ref}
          {...rest}>
          <LinesEllipsis {...ellipsis} />
        </MUITypography>
      );
    }
    return (
      <MUITypography
        className={clsx(classes.root, className, {
          [classes.ellipsis]: isEllipsis,
        })}
        ref={ref}
        {...rest}>
        {children}
      </MUITypography>
    );
  },
);

Typography.displayName = "Typography";

export default Typography;
